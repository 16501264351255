<template>
  <div class="pc owner">

    <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <NavbarOwner :translator="translator" :user="user"/>

    <div class="container px-4 title-primary" v-if="!isPreLoad" v-cloak>
      <h5 v-if="visibleDepartures.length == 1" class=""><i class="fal fa-sign-in-alt pr-2"></i> {{visibleDepartures.length}} {{ translator.sortida | toLower }}</h5>
      <h5 v-else class=""><i class="fal fa-sign-in-alt pr-2"></i> {{visibleDepartures.length}} {{ translator.sortides | toLower }}</h5>
    </div>

    <div
      class="container salidas-listado px-4"
      v-for="(day, index) in listDays"
      :key="index"
      v-cloak
    >
      <div
        data-toggle="collapse"
        :data-target="'#booking' + index"
        aria-expanded="true"
        :aria-controls="'#booking' + index"
        class="fecha-salida px-3 py-2 mb-0"
        style="cursor:pointer; display:flex; justify-content:space-between; background-color:#FFF; font-weight:bold;"
        @click="day.expanded === true ? day.expanded = false : day.expanded = true"
        v-cloak
      >
        {{ day.day }}
        <i v-if="day.expanded" style="font-size: 18px;" class="arrow fal fa-angle-up"></i>
        <i v-else style="font-size: 18px;" class="arrow fal fa-angle-down"></i>
      </div>

      <div
        v-for="(booking, index1) in visibleDepartures"
        :key="index1"
        class="collapse multi-collapse show"
        :id="'booking' + index"
      >
        <div class="bookings" v-show="day.day == booking._departure_date">
          <router-link
            class="card card-salidas mb-2"
            :to="{ name: 'OwnerBookingInfo', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
            v-cloak
          >
            <div class="card-body py-1">
              <h5 class="card-title" v-cloak>
                {{ booking.lodging_name }} - {{ booking.reservation }}
                <small v-if="booking.departure_time.length > 0" v-cloak
                  >{{ booking.departure_time }} h</small
                >
              </h5>
              <p v-cloak>
                {{ booking.nights }}
                <span v-if="booking.nights == 1" v-cloak>{{
                  translator.nit | toLower
                }}</span>
                <span v-else v-cloak>{{ translator.nits | toLower }}</span>

                - {{ booking.guests }}

                <span v-if="booking.guests == 1" v-cloak>{{
                  translator.hoste | toLower
                }}</span>
                <span v-else v-cloak>{{ translator.hostes | toLower }}</span>

                ({{ booking.adults }}-{{ booking.children }}-{{
                  booking.babies
                }})
              </p>
              <p class="small" v-cloak>
                {{ booking.customer_name }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("input[type=text]").forEach((node) =>
    node.addEventListener("keypress", (e) => {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    })
  );
});

import NavbarOwner from "@/components/NavbarOwner.vue";
import { mapState } from "vuex";

export default {
  name: "OwnerDepartures",
  components: {
    NavbarOwner,
  },
  
  data() {
    return {
      isPreLoad: false,
      visibleDepartures: [],
      listDays: [],
    };
  },

  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["departures"]),
  },

  watch: {
    translator() {
      this.$store.dispatch("owner/getDepartures", { usr: this.$route.query.id }); 
    },
    departures(newQ) {
      this.resetVisibleBookings(newQ);
      sessionStorage.setItem('listado', 'Departures');
      this.isPreLoad = false;
    },
  },

  async created() {
    try {
      this.isPreLoad = true;
      this.translator
        ? await this.$store.dispatch("owner/getDepartures", { usr: this.$route.query.id })
        : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    resetVisibleBookings(departures) {
      this.visibleDepartures = [];
      this.listDays = [];

      departures.forEach( booking => {
        booking.guests = parseInt( booking.adults ) + parseInt( booking.children ) + parseInt( booking.babies );
        booking._departure_date = this.formatDate( booking.departure_date );
        this.visibleDepartures.push( booking );

        let addDay = true;
        this.listDays.forEach( day => {
          day.day === booking._departure_date ? addDay = false : '';
        })
        addDay ? this.listDays.push({ day: String(booking._departure_date), expanded: true, order: booking.departure_date }) : '';
      });
      this.listDays.sort((a, b) => 1 * (Date.parse(a.order) - Date.parse(b.order)));
    },
    formatDate(date) {
      const allMonths = this.translator.monthShort;
      const allWeekdays = this.translator.weekdayShort;
      const months = [
        allMonths[0],
        allMonths[1],
        allMonths[2],
        allMonths[3],
        allMonths[4],
        allMonths[5],
        allMonths[6],
        allMonths[7],
        allMonths[8],
        allMonths[9],
        allMonths[10],
        allMonths[11],
      ];
      const week = [
        allWeekdays[0],
        allWeekdays[1],
        allWeekdays[2],
        allWeekdays[3],
        allWeekdays[4],
        allWeekdays[5],
        allWeekdays[6],
      ];

      let long_date = "";
      let day = date.slice(8, 10);
      let month = date.slice(5, 7);
      let year = date.slice(0, 4);

      //console.log(date + ', day: ' + day + ', month: ' + month + ', year: ' + year);

      let new_date = new Date(date);
      let day_week = new_date.getUTCDay();
      long_date =
        week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

      return long_date;
    },

    searchReservation: function (event) {
      this.searchReference = event.target.value;
      this.filterResults();
      document.getElementById(event.path[0].id).blur();
    },

    filterResults() {
      let result = self.departures.filter((booking) => {
        let display = true;

        //Busqueda por número de reserva o nombre
        if (display) {
          if (this.searchReference.length > 0) {
            display = false;

            let booking_name = booking.lodging_name.toLowerCase();
            let booking_reservation = booking.reservation;
            let ref_name = this.searchReference.toLowerCase();

            if (
              booking_name.indexOf(ref_name) > -1 ||
              booking_reservation.indexOf(ref_name) > -1
            ) {
              display = true;
            }
          }
        }

        return display;
      });

      this.visibleDepartures = result;
    },
  },
  filters: {
    toLower(value) {
      if (!value) return "";
      value = value.toString();
      return value.toLowerCase();
    },
  },
};
</script>

<style>
body {
  background-color: #f6f7f9;
}
.bookings {
    padding: 10px 16px;
    background: #FFF;
}
</style>