<template>
    <div class="pc owner">

        <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

        
        <NavbarOwner :translator="translator" :user="user"/>
        

        <div class="container px-4 title-primary" v-if="!isPreLoad" v-cloak>
            <h5 v-if="visibleArrivals.length == 1" class=""><i class="fal fa-sign-in-alt pr-2"></i> {{visibleArrivals.length}} {{ translator.arribada | toLower }}</h5>
            <h5 v-else class=""><i class="fal fa-sign-in-alt pr-2"></i> {{visibleArrivals.length}} {{ translator.arribades | toLower }}</h5>
        </div>

        <div class="container llegadas-listado px-4"
             v-for="(day, index) in listDays"
             :key="index"
             v-cloak>
            <div data-toggle="collapse"
                 :data-target="'#booking' + index"
                 aria-expanded="true"
                 :aria-controls="'#booking' + index"
                 class="fecha-llegada px-3 py-2 mb-0"
                 style="cursor:pointer; display:flex; justify-content:space-between; background-color:#FFF; font-weight:bold;"
                 @click="day.expanded === true ? day.expanded = false : day.expanded = true"
                 v-cloak>
                {{ day.day }}
                <i v-if="day.expanded" style="font-size: 18px;" class="arrow fal fa-angle-up"></i>
                <i v-else style="font-size: 18px;" class="arrow fal fa-angle-down"></i>
            </div>

            <div v-for="(booking, index1) in visibleArrivals"
                 :key="index1"
                 class="collapse multi-collapse show"
                 :id="'booking' + index">
                <div class="bookings" v-show="day.day == booking._arrival_date">
                    <router-link class="card card-llegadas mb-2"
                                 :to="{ name: 'OwnerBookingInfo', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                                 v-cloak>
                        <div class="card-body py-1">
                            <h5 class="card-title" v-cloak>
                                {{ booking.lodging_name }} - {{ booking.reservation }}
                                <small v-if="booking.arrival_time.length > 0" v-cloak>{{ booking.arrival_time }} h</small>
                            </h5>
                            <p v-cloak>
                                {{ booking.nights }}
                                <span v-if="booking.nights == 1" v-cloak>
                                    {{ translator.nit | toLower }}
                                </span>
                                <span v-else v-cloak>{{ translator.nits | toLower }}</span>

                                - {{ booking.guests }}

                                <span v-if="booking.guests == 1" v-cloak>
                                    {{ translator.hoste | toLower }}
                                </span>
                                <span v-else v-cloak>{{ translator.hostes | toLower }}</span>
                                ({{ booking.adults }}-{{ booking.children }}-{{ booking.babies }})
                            </p>
                            <p class="small" v-cloak>
                                {{ booking.customer_name }}
                            </p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll("input[type=text]").forEach((node) =>
            node.addEventListener("keypress", (e) => {
                if (e.keyCode == 13) {
                    e.preventDefault();
                }
            })
        );
    });

    import NavbarOwner from "@/components/NavbarOwner.vue";
    import { mapState } from "vuex";

    // TODO: Crear un js amb m�todes comuns, i importar-los aqu�, nom�s els que utilitzarem aqu�

    // TODO: Crear servei pel translator que ho posi en sessionStorage, per� de forma global, perqu� no s'hagi de carregar a cada pag.

    export default {
        name: "OwnerArrivals",
        components: {
            NavbarOwner,
        },
        data() {
            return {
                isPreLoad: false,
                visibleArrivals: [],
                listDays: [],
            };
        },

        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("owner", ["arrivals"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("owner/getArrivals", { usr: this.$route.query.id })
            },
            arrivals(newQ) {
                this.resetVisibleBookings(newQ);
                sessionStorage.setItem('listado', 'Arrivals');
                this.isPreLoad = false;
            },
        },

        async created() {
            try {
                this.isPreLoad = true;
                this.translator
                 ? await this.$store.dispatch("owner/getArrivals", { usr: this.$route.query.id })
                 : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            resetVisibleBookings(arrivals) {
                this.visibleArrivals = [];
                this.listDays = [];

                const formatDate = (date) => date.toISOString().slice(0, 10);
                const fechaHoy = formatDate(new Date());  

                arrivals.forEach(booking => {
                    booking.guests = parseInt(booking.adults) + parseInt(booking.children) + parseInt(booking.babies);
                    booking._arrival_date = this.formatDate(booking.arrival_date);

                    //Reservas de hoy en adelante
                    if ( Date.parse(booking.arrival_date) >= Date.parse(fechaHoy) ) { 
                        this.visibleArrivals.push(booking);
                        let addDay = true;
                        this.listDays.forEach(day => {
                            day.day === booking._arrival_date ? addDay = false : '';
                        })
                        addDay ? this.listDays.push({ day: String(booking._arrival_date), expanded: true, order: booking.arrival_date }) : '';
                    }
                });
                this.listDays.sort((a, b) => 1 * (Date.parse(a.order) - Date.parse(b.order)));
            },
            formatDate(date) {
                const allMonths = this.translator.monthShort;
                const allWeekdays = this.translator.weekdayShort;
                const months = [
                    allMonths[0],
                    allMonths[1],
                    allMonths[2],
                    allMonths[3],
                    allMonths[4],
                    allMonths[5],
                    allMonths[6],
                    allMonths[7],
                    allMonths[8],
                    allMonths[9],
                    allMonths[10],
                    allMonths[11],
                ];
                const week = [
                    allWeekdays[0],
                    allWeekdays[1],
                    allWeekdays[2],
                    allWeekdays[3],
                    allWeekdays[4],
                    allWeekdays[5],
                    allWeekdays[6],
                ];

                let long_date = "";
                let day = date.slice(8, 10);
                let month = date.slice(5, 7);
                let year = date.slice(0, 4);

                //console.log(date + ', day: ' + day + ', month: ' + month + ', year: ' + year);

                let new_date = new Date(date);
                let day_week = new_date.getUTCDay();
                long_date =
                    week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

                return long_date;
            },
        },
        filters: {
            toLower(value) {
                if (!value) return "";
                value = value.toString();
                return value.toLowerCase();
            },
        },
    };
</script>

<style>
body {
    background-color: #f6f7f9;
}
.bookings {
    padding: 10px 16px;
    background: #FFF;
}
</style>