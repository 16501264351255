<template>
  <div class="pc owner">
    
    <NavbarOwner :translator="translator" :user="user" />

    <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="container px-4 title-primary">
      <div class="row">
        <div class="col pr-0 mb-3">
          <h5 v-cloak><i class="fal fa-calendar"></i> {{ translator.calendari }}</h5>
        </div>
      </div>
    
      <div class="row">
        <div class="col">

          <!-- Calendario Mensual -->
            <div class="c-mensual">
              <div class="container mt-4 calendario-checkin-manager">
                <div class="col">
                  
                  <!-- Listado propiedades -->
                  <section class="section-select-property">
                    <select v-model="propertySelected" class="select-property">
                      <option disabled value="">{{ translator.propietat }}</option>
                      <option v-for="property in ownerProperties" :key="property.property_id" :value="{ id: property.property_id, text: property.property_name }">{{ property.property_name }}</option>
                    </select>
                  </section>
                          
                  <section v-if="muestraDias.length > 0">

                    <!-- Flexas -->
                    <div class="d-flex justify-content-between py-2 px-2 selector-monthly">
                      <div @click="prevMonth" class="arrow-pointer"><i class="fal fa-chevron-left"></i></div>
                      <div class="name-month"> {{ nombreMes }} {{ moment(datefrom).format('yyyy') }}</div>
                      <div @click="nextMonth" class="arrow-pointer"><i class="fal fa-chevron-right"></i></div>
                    </div>

                    <!-- Días semana -->
                    <div class="flex days">
                      <div v-for="(dia, index) in diasSemana" :key="index" class="numMes calendar-header">{{ dia }}</div>
                    </div> 

                    <!-- Número dias -->
                    
                    <div class="flex">
                  
                      <div v-for="(d, index) in muestraDias" :key="index" class="numMes" @click="marcado(d.fecha)">
                  
                        <!-- Ocupado -->
                        <div v-if="d.estado == 'ocupado'" class="cont-dia dia-ocupado" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>
                        
                        <!-- libre -->
                        <div v-if="d.estado == ''" class="cont-dia" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- checkin -->
                        <div v-if="d.estado == 'checkin'" class="cont-dia primer-dia-ocupado" >
                          <div class="num-dia">{{ d.dia }}</div>
                          <span class="enlace">
                            <router-link :to="{ name: 'OwnerBookingInfo', params: { bookingId: d.reservation }, query: { id: $route.query.id }}">
                              <i class="fas fa-info-circle fa-lg"></i>
                            </router-link>
                          </span>
                        </div>
                        
                        <!-- checkout -->
                        <div v-if="d.estado == 'checkout'" class="cont-dia ultimo-dia-ocupado" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- Bloquado -->
                        <div v-if="d.estado == 'bloqueado'" class="cont-dia bloqueado" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- salida-bloqueada -->
                        <div v-if="d.estado == 'salida-bloqueada'" class="cont-dia entrada-bloqueada" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- entrada-bloqueada -->
                        <div v-if="d.estado == 'entrada-bloqueada'" class="cont-dia salida-bloqueada" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- checkout-and-block -->
                        <div v-if="d.estado == 'checkout-and-block'" class="cont-dia ultimo-dia-ocupado-bloqueado" >
                          <div class="num-dia">{{ d.dia }}</div>
                        </div>

                        <!-- Inout -->
                        <div v-if="d.estado == 'inout'" class="cont-dia dia-entrada-salida" >
                          <div class="num-dia">{{ d.dia }}</div>
                          <span class="enlace">
                            <router-link :to="{ name: 'OwnerBookingInfo', params: { bookingId: d.reservation }, query: { id: $route.query.id }}">
                              <i class="fas fa-info-circle fa-lg"></i>
                            </router-link>
                          </span>
                        </div>

                        <!-- block-and-checkin -->
                        <div v-if="d.estado == 'block-and-checkin'" class="cont-dia primer-dia-ocupado-bloqueado" >
                          <div class="num-dia">{{ d.dia }}</div>
                          <span class="enlace">
                            <router-link :to="{ name: 'OwnerBookingInfo', params: { bookingId: d.reservation }, query: { id: $route.query.id }}">
                              <i class="fas fa-info-circle fa-lg"></i>
                            </router-link>
                          </span>
                        </div>          

                      </div>
                    </div>
                  </section>

                  <!-- Modal crear/bloquear reserva-->
                  <div class="modal fade" id="modalCenter" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="modalLongTitle" v-if="propertySelected != ''">{{ propertySelected.text }}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">

                          <!-- Radio buttons -->
                          <div class="d-flex justify-content-center">
                            <div class="form-check">
                              <input type="radio" v-model="typeBookingOrBlock" class="form-check-input" id="booking" value="booking">
                              <label class="form-check-label" for="booking">
                                {{ translator.reserva }}
                              </label>
                            </div>
                            <div class="form-check ml-4">
                              <input type="radio" v-model="typeBookingOrBlock" class="form-check-input" id="block" value="block">
                              <label class="form-check-label" for="block">
                                {{ translator.bloqueig }}
                              </label>
                            </div>
                          </div>

                          <!-- Reserva form -->
                          <div v-if="typeBookingOrBlock == 'booking'" class="row">
                            <!-- <div class="col-12 mb-3">
                              <label for="selectProperty" class="form-label">{{ translator.propietat }}</label>
                              <select v-model="modalSelectProperty" class="form-control" id="selectProperty">
                                <option v-for="prop in calendars.rows" :key="prop.lodging_id" :value="prop.lodging_id + '|' + prop.label">{{ prop.label }}</option>
                              </select>
                            </div> -->
                            <div class="col-7 mb-3">
                              <label for="selectDate" class="form-label">{{ translator.checkin}}</label>
                              <input type="date" id="selectDate" v-model="selectDay" class="form-control">
                            </div>
                            <div class="col-5 mb-3">
                              <label for="numberNights" class="form-label">{{ translator.nnits }}</label>
                              <input type="number" id="numberNights" v-model="newBooking.numberNights" class="form-control">
                            </div>

                            <!-- <div class="col-12 mb-3">
                              <label for="name" class="form-label">{{ translator.nom }}</label>
                              <input v-model="newBooking.name" type="text" class="form-control" id="name">
                            </div>
                            <div class="col-12 mb-3">
                              <label for="surname" class="form-label">{{ translator.cognoms }}</label>
                              <input v-model="newBooking.surname" type="text" class="form-control" id="surname">
                            </div> 
                            <div class="col-12 mb-3">
                              <label for="email" class="form-label">{{ translator.email }}</label>
                              <input v-model="newBooking.email" type="email" class="form-control" id="email">
                            </div>
                            <div class="col-12 mb-3">
                              <label for="phone" class="form-label">{{ translator.telefon }}</label>
                              <input v-model="newBooking.phone" type="tel" class="form-control" id="phone">
                            </div>-->

                            <div class="col-4 mb-3">
                              <label for="adults" class="form-label">{{ translator.adults }}</label>
                              <input v-model="newBooking.adults" type="number" class="form-control" id="adults">
                            </div>
                            <div class="col-4 mb-3">
                              <label for="children" class="form-label">{{ translator.nens }}</label>
                              <input v-model="newBooking.children" type="number" class="form-control" id="children">
                            </div>
                            <div class="col-4 mb-3">
                              <label for="babies" class="form-label">{{ translator.bebes }}</label>
                              <input v-model="newBooking.babies" type="number" class="form-control" id="babies">
                            </div>

                            <!-- <div class="col-6 mb-3">
                              <label for="amount" class="form-label">{{ translator.import }}</label>
                              <div class="input-group">
                                <input v-model="newBooking.amount" type="number" class="form-control" id="amount">
                                <span class="input-group-text" style="border-right:1px solid #ced4da">€</span>
                              </div>
                            </div> -->
                            
                            <div class="col-12 mb-3">
                              <label for="remarks" class="form-label">{{ translator.observacions }}</label>
                              <div class="input-group">
                                <textarea v-model="newBooking.remarks" class="form-control" id="remarks"></textarea>
                              </div>
                            </div>
                          </div>

                          <!-- Bloqueo -->
                          <div v-if="typeBookingOrBlock == 'block'" class="row">
                            <!-- <div class="col-12 mb-3">
                              <label for="selectProperty" class="form-label">{{ translator.propietat }}</label>
                              <select v-model="modalSelectProperty" class="form-control" id="selectProperty">
                                <option v-for="prop in calendars.rows" :key="prop.lodging_id" :value="prop.lodging_id + '|' + prop.label">{{ prop.label }}</option>
                              </select>
                            </div> -->
                            <div class="col-sm-12 col-xs-12 mb-3">
                              <div class="form-check form-check-inline">
                                <input v-model="newBlock.block" class="form-check-input" type="radio" id="addblock" value="true">
                                <label class="form-check-label" for="addblock">{{ translator.bloquejar }}</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input v-model="newBlock.block" class="form-check-input" type="radio" id="unblock" value="false">
                                <label class="form-check-label" for="unblock">{{ translator.desbloquejar }}</label>
                              </div>
                            </div>
                            <div class="col-sm-6 col-xs-12 mb-3">
                              <label for="initialBlock" class="form-label">inicio</label>
                              <input type="date" id="initialBlock" v-model="selectDay" class="form-control">
                            </div>
                            <div class="col-sm-6 col-xs-12 mb-3">
                              <label for="numberNights" class="form-label">{{ translator.nnits }}</label>
                              <input type="number" id="numberNights" v-model="newBlock.numNights" class="form-control">
                            </div>
                          </div>

                          <div v-if="error.length > 0" class="alert alert-danger" role="alert">
                            <p v-for="(err, index) in error" :key="index">{{ err }}</p>
                          </div>

                        </div>
                        <div class="modal-footer">
                          <button @click="saveBookingOrBlock" type="button" class="btn btn-primary">{{ translator.guardar }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import NavbarOwner from "@/components/NavbarOwner.vue";
const $ = require("jquery");

export default {
  name: "OwnerCalendar",

  components: {
    NavbarOwner,
  },

   watch: {
    translator() {
      this.diasSemana = [this.translator.weekdayShort[1], this.translator.weekdayShort[2], this.translator.weekdayShort[3], this.translator.weekdayShort[4], this.translator.weekdayShort[5], this.translator.weekdayShort[6], this.translator.weekdayShort[0]]
      this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id })
    },
    propertySelected() {
      this.loadProperty();
      this.nombreMes = this.translator.monthNames[ this.moment(this.datefrom).month() ]
    },
    propietariCalendari() {
      this.colocaDias();
    },
    ownerProperties() {
      this.ownerProperties.length > 0 ? this.propertySelected = { id: this.ownerProperties[0].property_id, text: this.ownerProperties[0].property_name } : '';
    }
  },

  data() {
    return {
      isPreLoad: false,
      propertySelected: '',
      fechaMes: this.moment(new Date()),
      diasSemana: '',
      numeroMes: this.moment(this.fechaMes).month(),
      nombreMes: '',
      dias: [],
      muestraDias: [],
      datefrom: this.moment(new Date()).format('yyyy-MM-DD'),

      //modal
      error: [],
      selectDay: '',
      modalSelectProperty: '',
      typeBookingOrBlock: '',
      propertyId: '',
      newBooking: {
        numberNights: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        amount: '',
        adults: '',
        children: '',
        babies: '',
        remarks: '',
      },
      newBlock: {
        block: '',
        numNights: '',
      }
    };
  },

  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["ownerProperties","propietariCalendari"]),
  },

  async created() {
    try {
      this.isPreLoad = true;
      if (this.translator) {
        this.diasSemana = [this.translator.weekdayShort[1], this.translator.weekdayShort[2], this.translator.weekdayShort[3], this.translator.weekdayShort[4], this.translator.weekdayShort[5], this.translator.weekdayShort[6], this.translator.weekdayShort[0]];
        await this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id });
      } else {
        await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isPreLoad = false;
    }
  },

  methods: {
    async getCalendars() {
      this.ShowDays = [];
      this.dias = [];
      this.datefrom = this.moment(this.datefrom).startOf('month').format("yyyy-MM-DD");
      await this.$store.dispatch("owner/getPropietariCalendari",
          {
            usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
            id: this.propertySelected.id,
            month: this.moment(this.datefrom).format('M'),
            year: this.moment(this.datefrom).format('yyyy')
          }
        );
    },
    async loadProperty() {
      await this.getCalendars();
    },
    orderWSData() {
      this.dias = [];
      let isCheckout = false;

      //Object.values(this.propietariCalendari.rows).forEach(row => {
        isCheckout = false;
        let isBlock = false;
        let count = 0;

        Object.values(this.propietariCalendari.rows).forEach( row => {
          let fecha = this.moment(this.datefrom).startOf('month').add(count, 'd').format("yyyy-MM-DD");
          let dia = this.moment(fecha).format('D');
          let estado = "";
          let id = this.propertySelected.id;
          let name = this.propertySelected.text;
          let reservation = row.reservation;
          let arrival = row.arrival;
          let departurte = row.departure;

          if( new Date(fecha) >= new Date(arrival) && new Date(fecha) <= new Date(departurte) ) {
            estado = "ocupado";
          }

          if ( fecha == arrival ) {
            if ( isCheckout ) {
              estado = "inout";
              isCheckout = false;
            } else {
              estado = 'checkin';
              isCheckout = false;
            }
          } else {
            if ( isCheckout ) {
              estado = 'checkout';   
              isCheckout = false;
            }
          }

          if ( this.moment(fecha).format('yyyy-MM-DD') === this.moment(departurte).add(-1, 'd').format('yyyy-MM-DD') && !isCheckout) {
            isCheckout = true;
          }

          if ( reservation >= 1 && reservation <= 9 ) {
            if ( estado == "checkout" ) {
              estado = 'checkout-and-block';
            } else {
              isBlock ? estado = 'bloqueado' : estado = 'entrada-bloqueada';
            }
              
            isBlock = true;
          }

          if ( reservation == 0 || reservation >= 10 ) {
            if ( isBlock && estado == 'checkin' ) {
              estado = 'block-and-checkin';
            } else {
              isBlock ? estado = 'salida-bloqueada' : null;
            }
            isBlock = false;
          }

          this.dias.push({ fecha, id, name, arrival, departurte, reservation, estado, dia, });
          count ++;
        })
    },
    marcado(dia = null) {
      if ( this.user.update_availability == 'true' ) {
        this.selectDay = dia;
        this.modalSelectProperty = this.propertySelected.id;
        $('#modalCenter').modal('show');
      }
    },
    async saveBookingOrBlock() {
      //modalSelectProperty = id + '|' + name 
      const arrayStr = this.modalSelectProperty.split('|')
      this.propertyId == '' ? this.propertyId = arrayStr[0] : null
      // Reserva
      if (this.typeBookingOrBlock == 'booking') {

        this.error = [];
        this.modalSelectProperty == '' ? this.error.push(this.translator.faltapropietat) : null;
        this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
        this.newBooking.numberNights == '' ? this.error.push(this.translator.faltanumnits) : null;
        //this.newBooking.name == '' ? this.error.push(this.translator.faltanom) : null;
        //this.newBooking.amount == '' || this.newBooking.amount <= 0 ? this.error.push('Falta importe') : null;

        if ( this.error.length == 0 ) {

          await this.$store.dispatch( "owner/postReservaAfegir", {
            usr: String(this.$route.query.id).replace(/[ ]/g, '+'), //String
            //nom: this.newBooking.name, //String
            nom: this.translator.propietari, //String
            cognom: this.newBooking.surname, //String
            telefon: this.newBooking.phone, //String
            email: this.user.user_email, //String
            adults: this.newBooking.adults === '' ? 0 : this.newBooking.adults, //Int
            joves: this.newBooking.children === '' ? 0 : this.newBooking.children, //Int
            bebes: this.newBooking.babies  === '' ? 0 : this.newBooking.babies, //Int
            observacions: this.newBooking.remarks, //String
            lodgingId: arrayStr[0], //Int
            entrada: this.selectDay, //Date
            nits: parseInt(this.newBooking.numberNights), //Int
            preu: this.newBooking.amount == '' ? 0 : this.newBooking.amount //Decimal
          } );
          
          this.typeBookingOrBlock = this.modalSelectProperty = this.selectDay = this.newBooking.name = this.newBooking.surname = this.newBooking.email = this.newBooking.phone = this.newBooking.adults = this.newBooking.children = this.newBooking.babies = this.newBooking.remarks = this.newBooking.amount = this.newBooking.numberNights = '';
          $('#modalCenter').modal('hide');
          setTimeout(()=>{
            this.getCalendars();
          }, 800)
        }
      // Bloqueo
      } else if (this.typeBookingOrBlock == 'block') {

        // Bloqueo
        this.error = [];
        this.modalSelectProperty == '' ? this.error.push(this.translator.faltapropietat) : null;
        this.block == '' ? this.error.push(`${this.translator.bloquejar}/${this.translator.desbloquejar}`) : null;
        this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
        this.newBlock.numNights == '' ? this.error.push(this.translator.faltanumnits) : null;

        if ( this.error.length == 0 ) {
          await this.$store.dispatch( "owner/postBloquejarCalendari", { 
              usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
              lodgingId: this.propertySelected.id,
              data: this.selectDay,
              nits: parseInt(this.newBlock.numNights),
              bloquejar: this.newBlock.block == "true" ? true : false,
            } );
          this.getCalendars();
          this.newBlock.numNights = this.modalSelectProperty = this.selectDay = '';
          $('#modalCenter').modal('hide');
        }
      }
    },
    colocaDias() {
      this.orderWSData();
      setTimeout( () => {
        const inicioMes = this.moment(this.fechaMes).startOf('month');
        const primerDiaSemana = this. moment(inicioMes).isoWeekday();
        this.numeroMes = this.moment(this.fechaMes).month() + 1
        this.nombreMes = this.translator.monthNames[ this.moment(this.fechaMes).month() ]
        this.muestraDias = [];

        let isFirtDays = true;
        for (let i = 1; i <= 7; i++) {
          if ( i === primerDiaSemana ) {
            isFirtDays = false;
            
            this.dias.forEach(d => {
              this.muestraDias.push(d);
            });

          } else {
            const dia = { arrival: '', departurte: '', dia: '', estado: '', fecha: '', id: '', name: '', reservation: '' }
            // Pinta en cuadros antes del primer día
            if (isFirtDays) {
              this.muestraDias.push(dia)
            }
          }
        }
        // Pinta cuadros después del último día
        const dia = { arrival: '', departurte: '', dia: '', estado: '', fecha: '', id: '', name: '', reservation: '' }
        const finMes = this.moment(this.fechaMes).endOf('month');
        const ultimoDiaSemana = this. moment(finMes).isoWeekday();
        const calculaCuadrosFinales = 7 - ultimoDiaSemana;
        for (let a = 1; a <= calculaCuadrosFinales; a++) {
          this.muestraDias.push(dia);
        }
      }, 300)
    },
    nextMonth() {
      this.fechaMes = this.fechaMes.add(1, 'month');
      this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
      this.getCalendars();
      this.colocaDias();
    },
    
    prevMonth() {
      this.fechaMes = this.fechaMes.add(-1, 'month');
      this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
      this.getCalendars();
      this.colocaDias();
    },
  },
  filters: {
    modalNombre(str) {
      if (!str) return "";
      const arrayStr = str.split('|');
      return arrayStr[1]
    }
  },
};

</script>

<style lang="scss">
body {
  background-color: #f6f7f9;
}

.select-property {
  box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, .05);
}

/* C. Mensual */
.c-mensual .select-property {
  box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, .05);
}

.c-mensual .section-select-property {
  width: 90.9%;
  margin: auto;
}

.c-mensual .cont-dia {
  width: 100%;
  height: 100%;
}
.c-mensual .name-month {
  min-width: 90px;
  text-align: center;
  margin: 5px;
}
.c-mensual .arrow-pointer {
  cursor: pointer;
  padding: 5px;
}
.c-mensual .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c-mensual .numMes {
  width: 13%;
  height: 48px;
  border-right: 1px solid #E3E3E3;
  border-top: 1px solid #E3E3E3;
  text-align: center;
  background-color: #FFF;
}
.c-mensual .selector-monthly {
  background-color: var(--azul-oscuro);
  color: #FFF;
  width: 90.9%;
  margin: auto;
}
.c-mensual .calendar-header {
  background-color: #ebeff3;
  color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-mensual .num-dia {
  color: #a1a1a1;
  text-align: start;
  padding: 0 8px;
}
.c-mensual .calendario-movil > tbody > tr:hover {
    background-color: none;
}
.c-mensual span.num-dia {
    left: 5px;
    top: 5px;
    line-height: 1;
}
.c-mensual .calendario-movil > thead {
  background-color: var(--azul-oscuro);
  color: #FFF;
}
.c-mensual .calendario-movil .days {
  background-color: #ebeff3;
}
.c-mensual .calendario-movil td.not-currents {
  color: #c0c0c0;
  font-size: 14px;
  background-color: rgb(248, 248, 248);
}
.c-mensual .calendario-movil td.normal {
  font-size: 14px;
}

.c-mensual .calendario-movil td {
    position: relative;
    height: 48px;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
    width: 14.28571429%;
}
.c-mensual .ultimo-dia-ocupado {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD700;
    background: -moz-linear-gradient(-45deg, #FFD700 50%, transparent 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFD700), color-stop(50%,transparent)) !important;
    background: -webkit-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
    background: -o-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
    background: -ms-linear-gradient(-45deg, #FFD700 50%,transparent 50%) !important;
    background: linear-gradient(135deg, #FFD700 50%,transparent 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD700', endColorstr='transparent',GradientType=1 ) !important;
}
.c-mensual .ultimo-dia-ocupado-bloqueado {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD700;
    background: -moz-linear-gradient(-45deg, #FFD700 50%, #333 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFD700), color-stop(50%,#333)) !important;
    background: -webkit-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
    background: -o-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
    background: -ms-linear-gradient(-45deg, #FFD700 50%,#333 50%) !important;
    background: linear-gradient(135deg, #FFD700 50%,#333 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD700', endColorstr='#333',GradientType=1 ) !important;
}
.c-mensual .primer-dia-ocupado {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD700;
    background: -moz-linear-gradient(-45deg, #fff 50%, #FFD700 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#fff), color-stop(50%,#FFD700)) !important;
    background: -webkit-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
    background: -o-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
    background: -ms-linear-gradient(-45deg, #fff 50%,#FFD700 50%) !important;
    background: linear-gradient(135deg, #fff 50%,#FFD700 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#FFD700',GradientType=1 ) !important;
}
.c-mensual .primer-dia-ocupado-bloqueado {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD700;
    background: -moz-linear-gradient(-45deg, #333 50%, #FFD700 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#333), color-stop(50%,#FFD700)) !important;
    background: -webkit-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
    background: -o-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
    background: -ms-linear-gradient(-45deg, #333 50%,#FFD700 50%) !important;
    background: linear-gradient(135deg, #333 50%,#FFD700 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333', endColorstr='#FFD700',GradientType=1 ) !important;
}
.c-mensual .dia-ocupado {
    color: #FFD700;
    background-color: #FFD700;
}
.c-mensual .dia-entrada-salida {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFD700;
    background: -webkit-linear-gradient(315deg, #FFD700 42%, #FFF 10%, #FFD700 48%);
    background: linear-gradient(135deg, #FFD700 42%, #FFF 10%, #FFD700 48%);
}
.c-mensual .precio > i {
  color: var(--azul-oscuro) !important; 
}
.c-mensual .bloqueado {
  background-color: #333;
}

.c-mensual .entrada-bloqueada {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #333;
    background: -moz-linear-gradient(-45deg, #333 50%, #FFF 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#333), color-stop(50%,#FFF)) !important;
    background: -webkit-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
    background: -o-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
    background: -ms-linear-gradient(-45deg, #333 50%,#FFF 50%) !important;
    background: linear-gradient(135deg, #333 50%,#FFF 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333', endColorstr='#FFF',GradientType=1 ) !important;
}
.c-mensual .salida-bloqueada {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #333;
    background: -moz-linear-gradient(-45deg, #FFF 50%, #333 50%) !important;
    background: -webkit-gradient(linear, left top, right bottom, color-stop(50%,#FFF), color-stop(50%,#333)) !important;
    background: -webkit-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
    background: -o-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
    background: -ms-linear-gradient(-45deg, #FFF 50%,#333 50%) !important;
    background: linear-gradient(135deg, #FFF 50%,#333 50%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#333',GradientType=1 ) !important;
}
.c-mensual .enlace {
  position: relative;
  bottom: 15px;
  color: #007bff
}
.c-mensual .enlace i {
  color: #007bff
}
</style>