<template>
  <div class="pc owner">
    
    <NavbarOwner :translator="translator" :user="user" />

    <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="container px-4 title-primary">
      <div class="row">
        <div class="col pr-0 mb-3">
          <h5 v-cloak><i class="fal fa-calendar"></i> {{ translator.calendari }}</h5>
        </div>
      </div>
    
      <div class="row">
        <div class="col">

          <!-- Calendario Mensual -->
            <div class="c-mensual">
              <div class="mt-4 calendario-checkin-manager">
                  
                  <!-- Listado propiedades -->
                  <section class="section-select-property">
                    <select v-model="propertySelected" class="select-property">
                      <option disabled value="">{{ translator.propietat }}</option>
                      <option v-for="property in ownerProperties" :key="property.property_id" :value="{ id: property.property_id, text: property.property_name }">{{ property.property_name }}</option>
                    </select>
                  </section>

                  <!-- Selectores -->
                  <div class="d-flex section-select-property">
                    <div class="col-6 pl-0">
                      <!-- Tarifas -->
                      <select v-model="rateSelected" class="select-property">
                        <option v-for="rate in tarifas" :key="rate.rate_id" :value="rate.rate_id">{{ rate.rate_name }}</option>
                      </select>
                    </div>
                    <div class=" col-6 pr-0">
                      <!-- noches -->
                      <select v-model="nightsSeleted" class="select-property">
                        <option v-for="n in noches" :key="n.night_id" :value="n.night_id">{{ n.night_id }}</option>
                      </select>
                    </div>
                  </div>


                          
                  <section v-if="muestraDias.length > 0">

                    <!-- Flexas -->
                    <div class="d-flex justify-content-between py-2 px-2 selector-monthly">
                      <div @click="prevMonth" class="arrow-pointer"><i class="fal fa-chevron-left"></i></div>
                      <div class="name-month"> {{ nombreMes }} {{ moment(datefrom).format('yyyy') }}</div>
                      <div @click="nextMonth" class="arrow-pointer"><i class="fal fa-chevron-right"></i></div>
                    </div>

                    <!-- Días semana -->
                    <div class="flex days">
                      <div v-for="(dia, index) in diasSemana" :key="index" class="numMes calendar-header">{{ dia }}</div>
                    </div> 

                    <!-- Número dias -->
                    <div class="flex">
                  
                      <div v-for="(d, index) in muestraDias" :key="index" class="numMes" @click="marcado(d)">
                        <div class="cont-dia" >
                          <div class="num-dia">{{ d.dia }}</div>
                          {{ d.price | intNumber }}
                        </div>

                      </div>
                    </div>
                  </section>

                  <!-- Modal precios-->
                  <div class="modal fade" id="modalCenter" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="modalLongTitle" v-if="propertySelected != ''">{{ propertySelected.text }}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div class="modal-body">
                          <div class="container">
                              
                            <div class="d-flex">
                              <div class="inp-name"></div>
                              <div class="cont-prices" v-for="n in noches" :key="n.nights">
                                <span class="inp-preus text-center">{{ n.night_id }}</span>
                              </div>
                            </div>

                            <div v-for="(show, index) in showDataPopUp" :key="index" class="d-flex">
                              <div class="inp-name">
                                <span>{{ show[index].t_nom }}</span>
                              </div>
                              <div v-for="(inp, i) in show" :key="i" class="cont-prices">
                                <input type="number" 
                                      :value="inp.t_preu" 
                                      :tarNit="inp.t_nits" 
                                      :tarId="inp.t_id" 
                                      :disabled="inp.t_bloqueig == 'true'" 
                                      class="form-control inp-preus text-center" 
                                      :class="{'act-price': inp.t_bloqueig == 'false'}"
                                    >
                              </div>
                            </div>

                            <div class="row mt-4">
                              <div class="col">
                                <label for="desde">-des-de</label>
                                <input id="desde" v-model="desde" type="date" class="form-control">
                              </div>
                              <div class="col">
                                <label for="hasta">-fins-a</label>
                                <input id="hasta" v-model="hasta" type="date" :min="desde" class="form-control">
                              </div>
                            </div>
                          </div>
                          

                        </div>

                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" @click="disparaPrecios">{{ translator.guardar }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
          
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import NavbarOwner from "@/components/NavbarOwner.vue";
const $ = require("jquery");

export default {
  name: "OwnerPrices",

  components: {
    NavbarOwner,
  },

   watch: {
    translator() {
      this.diasSemana = [this.translator.weekdayShort[1], this.translator.weekdayShort[2], this.translator.weekdayShort[3], this.translator.weekdayShort[4], this.translator.weekdayShort[5], this.translator.weekdayShort[6], this.translator.weekdayShort[0]]
      this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id })
    },
    ownerProperties() {
      this.ownerProperties.length > 0 ? this.propertySelected = { id: this.ownerProperties[0].property_id, text: this.ownerProperties[0].property_name } : null;
      this.isPreLoad = false;
      this.resetLoading = true;
    },
    propertySelected() {
      this.getCalendarPrices();
      this.resetLoading = true;
      this.nombreMes = this.translator.monthNames[ this.moment(this.datefrom).month() ];
    },
    propietatPreus() { 
      this.colocaDias();
    },
    rateSelected(newQ, oldQ) {
      newQ != oldQ ? this.colocaDias() : null;
    },
    nightsSeleted(newQ, oldQ) {
      newQ != oldQ ? this.colocaDias() : null;
    },
    desde(newQ) {
      newQ > this.hasta ? this.hasta = newQ : null;
    }
  },

  data() {
    return {
      isPreLoad: false,
      propertySelected: '',
      fechaMes: this.moment(new Date()),
      diasSemana: '',
      numeroMes: this.moment(this.fechaMes).month(),
      nombreMes: '',
      dias: [],
      datefrom: this.moment(new Date()).format('yyyy-MM-DD'),
      muestraDias: [],
      tarifas: [],
      rateSelected: '',
      noches: [],
      nightsSeleted: '',
      resetLoading: false,
      updatePrice: {
        date: '',
        night_id: '',
        price: '',
        price_lock: '',
        rate_id: ''
      },
      showDataPopUp: [],
      desde: '',
      hasta: ''
    };
  },

  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["ownerProperties", "propietatPreus"]),
  },

  async created() {
    try {
      this.isPreLoad = true;
      if ( this.translator ) {
        this.diasSemana = [this.translator.weekdayShort[1], this.translator.weekdayShort[2], this.translator.weekdayShort[3], this.translator.weekdayShort[4], this.translator.weekdayShort[5], this.translator.weekdayShort[6], this.translator.weekdayShort[0]];
        await this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id });
      } else {
        await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
      }
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async getCalendarPrices() {
      this.datefrom = this.moment(this.datefrom).startOf('month').format("yyyy-MM-DD");
      const propetyId = this.propertySelected.id.split('.')[0];

      await this.$store.dispatch("owner/getPropietatPreus",
          {
            usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
            propertyid: propetyId,
            month: this.moment(this.datefrom).format('M'),
            year: this.moment(this.datefrom).format('yyyy')
          }
        );
    },

    marcado(update) {
      this.desde = this.hasta = update.date;
      //if ( this.user.update_prices == 'true' ) {
        this.updatePrice.date = update.date;
        this.updatePrice.night_id = update.night_id;
        this.updatePrice.price = update.price;
        this.updatePrice.price_lock = update.price_lock;
        this.updatePrice.rate_id = update.rate_id;

        let filterDate = [];
        let nights = [];
        let showOrder = [];
        this.showDataPopUp = [];

        this.propietatPreus.prices.forEach(prop => {
          nights.push(prop.night_id)

          // Cojo unicamente la fecha señalada
          if ( prop.date === update.date ) {
            this.tarifas.forEach(t => {
              if ( t.rate_id === prop.rate_id ) {
                prop.tarifa = t.rate_id
                prop.tarifa_nom = t.rate_name
              }
            })
            filterDate.push(prop);
          }
        })

        // Elimino noches duplicadas
        nights = [...new Set(nights)]

        // Creo un objeto con los datos 
        nights.forEach((dias, count) => {
          filterDate.forEach(el => {
            dias === el.night_id ? 
              showOrder.push({ 
                't_nits': count + 1, 
                't_nom': el.tarifa_nom, 
                't_id': el.rate_id,
                't_preu': parseInt(el.price), 
                't_bloqueig': el.price_lock, 
                't_dia': el.dia, 't_data': el.date 
              }) : null;
          })
        })

        // Recorro las tarifas y creo una fila por tarifa
        this.tarifas.forEach(t => {
          let fila = [];
          showOrder.forEach(el => {
            t.rate_name == el.t_nom ? fila.push(el) : null;
          })
          this.showDataPopUp.push(fila);
        })



        console.log('show', this.showDataPopUp);
        $('#modalCenter').modal('show');
      //}
    },
    colocaDias() {
      const inicioMes = this.moment(this.fechaMes).startOf('month');
      const primerDiaSemana = this.moment(inicioMes).isoWeekday();
      this.numeroMes = this.moment(this.fechaMes).month() + 1
      this.nombreMes = this.translator.monthNames[ this.moment(this.fechaMes).month() ]
      this.muestraDias = [];

      this.tarifas = this.propietatPreus.rates;
      this.noches = this.propietatPreus.nights;
      
      if ( this.resetLoading ) {
        this.rateSelected = this.propietatPreus.rates[0].rate_id;
        this.nightsSeleted = this.propietatPreus.nights[0].night_id;
      }
      this.resetLoading = false;

      let isFirtDays = true;
      for (let i = 1; i <= 7; i++) {
        if ( i === primerDiaSemana ) {
          isFirtDays = false;
          
          this.propietatPreus.prices.forEach(el => {
            el.dia = this.moment(el.date).format('D');
            this.rateSelected === el.rate_id && this.nightsSeleted === el.night_id ? this.muestraDias.push(el) : null;
          });

        } else {
          const dia = { date: '', night_id: '', price: '', price_lock: '', rate_id: '' }
          // Pinta en cuadros antes del primer día
          if (isFirtDays) {
            this.muestraDias.push(dia)
          }
        }
      }
      // Pinta cuadros después del último día
      const dia = { arrival: '', departurte: '', dia: '', estado: '', fecha: '', id: '', name: '', reservation: '' }
      const finMes = this.moment(this.fechaMes).endOf('month');
      const ultimoDiaSemana = this. moment(finMes).isoWeekday();
      const calculaCuadrosFinales = 7 - ultimoDiaSemana;
      for (let a = 1; a <= calculaCuadrosFinales; a++) {
        this.muestraDias.push(dia);
      }
    },
    nextMonth() {
      this.fechaMes = this.fechaMes.add(1, 'month');
      this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
      this.getCalendarPrices();
      this.colocaDias();
    },
    prevMonth() {
      this.fechaMes = this.fechaMes.add(-1, 'month');
      this.datefrom = this.moment(this.fechaMes).startOf('month').format('yyyy-MM-DD');
      this.getCalendarPrices();
      this.colocaDias();
    },
    disparaPrecios() {
      let listado = document.querySelectorAll('.act-price')
      listado.forEach(inp => {
        console.log('update -', this.desde, this.hasta, inp.value, inp.getAttribute('tarNit'), inp.getAttribute('tarId'))
        // this.$store.dispatch("owner/postPropietatPreus",
        //   {
        //     usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
        //     desde: this.desde,
        //     fins: this.hasta,
        //     preu: inp.value,
        //     night_id: inp.getAttribute('tarNit'),
        //     rate_id: inp.getAttribute('tarId')
        //   }
        // );
      })
    }
  },

  filters: { 
    intNumber(number) {
      if (!number) return "";
      const num = `${parseInt(number)}€`;
      return num;
    }
  },
};

</script>

<style lang="scss">
body {
  background-color: #f6f7f9;
}

.select-property {
  box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, .05);
}

/* C. Mensual */
.c-mensual .select-property {
  box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, .05);
}

.c-mensual .section-select-property {
  width: 90.9%;
  margin: auto;
}

.c-mensual .cont-dia {
  width: 100%;
  height: 100%;
}
.c-mensual .name-month {
  min-width: 90px;
  text-align: center;
  margin: 5px;
}
.c-mensual .arrow-pointer {
  cursor: pointer;
  padding: 5px;
}
.c-mensual .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c-mensual .numMes {
  width: 13%;
  height: 48px;
  border-right: 1px solid #E3E3E3;
  border-top: 1px solid #E3E3E3;
  text-align: center;
  background-color: #FFF;
}
.c-mensual .selector-monthly {
  background-color: var(--azul-oscuro);
  color: #FFF;
  width: 90.9%;
  margin: auto;
}
.c-mensual .calendar-header {
  background-color: #ebeff3;
  color: #212529;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-mensual .num-dia {
  color: #a1a1a1;
  text-align: start;
  padding: 0 8px;
}
.c-mensual .calendario-movil > tbody > tr:hover {
    background-color: none;
}
.c-mensual span.num-dia {
    left: 5px;
    top: 5px;
    line-height: 1;
}
.c-mensual .calendario-movil > thead {
  background-color: var(--azul-oscuro);
  color: #FFF;
}
.c-mensual .calendario-movil .days {
  background-color: #ebeff3;
}
.c-mensual .calendario-movil td.not-currents {
  color: #c0c0c0;
  font-size: 14px;
  background-color: rgb(248, 248, 248);
}
.c-mensual .calendario-movil td.normal {
  font-size: 14px;
}

.c-mensual .calendario-movil td {
    position: relative;
    height: 48px;
    text-align: center;
    vertical-align: middle;
    border-right: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
    width: 14.28571429%;
}


.inp-name {
  width: 80px;
}

.cont-prices {
  width: 50px;
}

.inp-preus {
  width: 100%;
  min-width: 40px;
  padding: 5px;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>