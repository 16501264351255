<template>
  <div class="pc owner">

    <NavbarOwner :translator="translator" :user="user" class="m-bottom"/>

    <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="container">

      <div class="salidas-listado mb-4">
          <h5 class="pb-1 pt-3">
              <i class="fal fa-file-invoice pr-1"></i>
              <span> {{ translator.factures }}</span>
          </h5>                
      </div>

      <div class="container-data px-4 py-1">
        <table class="listado-facturas">
          <tr class="line-head">
            <th>{{ translator.factures }}</th>
            <th>{{ translator.data }}</th>
            <th class="text-right">{{ translator.import }}</th>
          </tr>
          <tr v-for="(invoice, index) in invoices" :key="index">
            <td>
              <div class="td-flex">
                <span class="px-2" style="cursor:pointer;" v-if="invoice.invoice_pdf != ''" @click.prevent="requestPdf(invoice.invoice_pdf)"><i class="fal fa-file-pdf"></i></span>
                <span style="padding: 0px 12px" v-else>-</span>
                <span>{{ invoice.invoice_number }}</span>
              </div>
            </td>
            <td>{{ invoice.invoice_date }}</td>
            <td class="text-right">{{ invoice.invoice_amount | ereaseZero }} {{ invoice.invoice_currency }}</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import NavbarOwner from "@/components/NavbarOwner.vue";

export default {
  name: "OwnerInvoice",
  components: {
    NavbarOwner,
  },
  watch: {
    translator() {
      this.$store.dispatch("owner/getInvoices", { usr: this.$route.query.id });
    },
    pdf(newQ) {
      this.createPdf(newQ);
    }
  },
  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["invoices", "pdf"]),
  },
  data() {
    return {
      isPreLoad: false
    };
  },
  async created() {
    try {
      this.isPreLoad = true;
      this.translator
        ? await this.$store.dispatch("owner/getInvoices", { usr: this.$route.query.id })
        : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
    } catch (error) {
      console.error(error);
    } finally {
      this.isPreLoad = false;
    }
  },
  methods: { 
    async requestPdf(idPdf) {
      try {
        if ( idPdf != '' ) {
          this.isPreLoad = true;
          await this.$store.dispatch("owner/getPdf", { usr: this.$route.query.id, pdf: idPdf });
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isPreLoad = false;
      }
    },
    createPdf(pdf) {
      const sampleArr = this.base64ToArrayBuffer(pdf);
      this.openPDF(sampleArr);
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return bytes;
    },
    openPDF(byte) {
        const blob = new Blob([byte], { type: "application/pdf" });
        const fileUrl = window.URL.createObjectURL(blob);
        function detectMob() {
          const toMatch = [
              /Android/i,
              /webOS/i,
              /iPhone/i,
              /iPad/i,
              /iPod/i,
              /BlackBerry/i,
              /Windows Phone/i
          ];
          return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
          });
        }
        detectMob() 
          ? window.open(fileUrl, "_self") 
          : window.open(fileUrl); 
    },
  },
  filters: {
      ereaseZero(value) {
          if (!value) return "";
          value = String(value.includes(".00")) ? String(value).replace(".00", "") : ''
          return value;
      },
  },
}
</script>


<style lang="scss">
body {
  background-color: #f6f7f9;
}
.line-head {
    height: 50px !important;
    color: var(--colores-gris-medio);
    border-bottom: 1.5px solid #dee2e6;
}
.listado-facturas {
  width: 100%;
}
.container-data {
    background-color: #FFF;
}
.owner .container-data th {
  color: var(--colores-gris-medio)!important;
  font-weight: bold!important;
}
.owner .container-data tr:nth-child(1) {
  border-top: none;
}
.owner .container-data tr {
  height: 36px;
  border-top: 1px solid #dee2e656;
}
.owner .td-flex {
  display: flex;
  align-items: center;
}
.fa-file-pdf {
  color: red;
  font-size: 18px;
}
</style>