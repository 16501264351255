<template>
    <div class="pc owner">

        <NavbarOwner :translator="translator" :user="user" class="m-bottom" />

        <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div v-else>

            <div class="container py-3">
                <div class="row align-items-center">

                    <div class="col">
                        <h5><i class="fal fa-book"></i> {{ translator.reserves }}</h5>
                    </div>

                    <div class="col">
                        <h5>
                            <a v-if="any > new Date().getFullYear() - 1" class="p-1" href="#" @click="any += -1">
                                <i class="fal fa-angle-left fa-lg"></i>
                            </a>
                            <span>{{ any }}</span>
                            <a v-if="any < anyActual" class="p-1" href="#" @click="any += 1">
                                <i class="fal fa-angle-right fa-lg"></i>
                            </a>

                        </h5>
                    </div>
                    
                    <div class="col">
                        <button class="btn btn-primary" @click="descargaCSV"><i class="far fa-file-download"></i> {{ translator.descarregar }}</button>
                    </div>
                </div>
            </div>



            <table class="propietari-reserves tabla-contenido">
                <thead>
                    <tr>
                        <th class="reserva">{{ translator.reserves }}</th>
                        <th class="nom">{{ translator.nom }}</th>
                        <th class="arrivada">{{ translator.arribades }}</th>
                        <th class="nits">{{ translator.nits }}</th>
                        <th class="persones">{{ translator.persones }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="r in propietariReserves" :key="r.reservation">
                        <td><span data-toggle="tooltip" data-placement="top" :title="`id: ${r.lodging_id}`">{{ r.reservation }}</span> </td>
                        <td><span data-toggle="tooltip" data-placement="top" :title="`${r.customer_name}`">{{ r.lodging_name }}</span></td>
                        <td>{{ r.arrival_date | formatDate }} {{ r.arrival_time }}</td>
                        <td>{{ r.nights }}</td>
                        <td><span data-toggle="tooltip" data-placement="top" :title="`${translator.adults}-${translator.nens}-${translator.bebes}`">{{ r.adults }}-{{ r.children }}-{{ r.babies }}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import NavbarOwner from '@/components/NavbarOwner.vue';
import { mapState } from "vuex";

export default {
    name: 'OwnerReservations',
    components: {
        NavbarOwner,
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("owner", ["propietariReserves", "propietariReservesDetall"]),
    },
    watch: {
        async translator() {
            this.$store.dispatch("owner/getPropietariReserves", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), any: this.any });
            this.isPreLoad = false;
        },
        any() {
            this.getPropietariReserves();
        },
        propietariReserves() {
            this.isPreLoad = false;
        },
        propietariReservesDetall(newQ) {
            this.createCSV(newQ);
        },
    },
    data() {
        return {
            isPreLoad: false,
            any: parseInt(this.moment(new Date()).format("yyyy")),
            anyActual: parseInt(this.moment(new Date()).format("yyyy"))
        };
    },
    async created() {
        try {
            this.isPreLoad = true;
            if (this.translator) {
                await this.$store.dispatch("owner/getPropietariReserves", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), any: this.any });
                this.isPreLoad = false;
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }

        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        async getPropietariReserves() {
            this.isPreLoad = true;
            await this.$store.dispatch("owner/getPropietariReserves", { usr: this.$route.query.id, any: this.any })
        },
        async descargaCSV() {
            await this.$store.dispatch("owner/getPropietariReservesDetall", { usr: this.$route.query.id, any: this.any })
        },

        createCSV(blobRaw) {

            let decodedString = atob(blobRaw);
            decodedString = decodedString.substring(3);
            // if (decodedString.charCodeAt(0) === 0xFEFF) {
            //     decodedString = decodedString.substr(1);
            // }
            //console.log(decodedString)
            const lines = decodedString.split('\n');
            console.log(lines)
            const uniqueLines = Array.from(new Set(lines));
            const uniqueString = uniqueLines.join('\n');
            let blob = new Blob([uniqueString]);


            const link = document.createElement('a');
            
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', `${this.translator.reserves}-${this.any}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }
        }
    },
    filters: { 
        formatDate(date) {
            if (!date) return "";
            let [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`;
        }
    },
}
</script>


<style scoped>
body {
    background-color: #f6f7f9;
}

.tabla-contenido {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    overflow: auto; /* Añadir esta línea para permitir el scroll */
}

.tabla-contenido td,
.tabla-contenido th {
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 1rem;
}

.tabla-contenido thead th {
    text-align: center;
    font-weight: 600;
    padding: 8px;
    position: sticky;
    top: 67px;
    z-index: 1;
    width: 25vw;
    background: #dbdbdb;
}

.tabla-contenido td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
}

.tabla-contenido tbody th {
    font-weight: 600;
    text-align: left;
    position: relative;
}

/* .tabla-contenido thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
} */

.tabla-contenido tbody th {
    position: sticky;
    left: 0;
    background: #f6f7f9;
    z-index: 1;
}

@media (max-width: 800px) {
    .tabla-contenido thead th {
        padding: 5px;
    }

    .tabla-contenido {
        font-size: 12px;
    }

    .tabla-contenido tbody th {
        padding: 0.4rem 0.5rem;
    }
}
</style>