<template>
  <div class="doughnut-container">
    
    <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    :propietari-grafics="propietariGrafics"
    :id="doughnut-chart"
    />
    <h1 class="porcentaje">{{ translator.ocupacio }} <br> {{ this.propietariGrafics.valores[0] }}%</h1>
    
  </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { Doughnut } from 'vue-chartjs/legacy'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
  
  export default {
    name: 'DoughnutChart',
    components: {
      Doughnut
    },
    props: {
      chartId: {
        type: String,
        default: 'doughnut-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 200
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      },
      propietariGrafics: {
        type: Object,
        default: () => []
      }
      
    },
    data() {
      return {
        doughnut: null,
        chart: null,
        chartData: {
          labels: this.propietariGrafics.labels,
          datasets: [
            {
              backgroundColor: this.propietariGrafics.colores,
              data: this.propietariGrafics.valores
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem) {
                  let label = tooltipItem.label || '';
                  if (label) {
                    label += ': ';
                  }
                  label += tooltipItem.raw + '%';
                  return label;
                }
              }
            }
          },
        }
      }
    },
    computed: {
      ...mapState("utils", ["translator"]),
    },
  }
  </script>
  
  <style scoped>

.doughnut-container {
    position: relative;
    width: 100%;
}
h1 {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.1rem
}

@media (min-width: 391px) {
  h1 {
    top: 45%;
  }
}

  </style>

