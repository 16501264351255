<template>
    <div class="pc owner">

        <NavbarOwner :translator="translator" :user="user" class="m-bottom" />

        <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

        <div class="container">
            <div class="row d-flex justify-content-between align-items-center ">

                <div class="col">
                    <div class="salidas-listado mb-4">
                        <h5 class="pb-1 pt-3">
                            <i class="fal fa-file-invoice pr-1"></i>
                            <span> {{ translator.liquidacions }}</span>
                        </h5>
                    </div>
                </div>

                <div class="col d-flex">
                    <button class="btn btn-primary btn-descargar-csv mr-2" @click="descargaCSVLiquidacions">
                        <i class="far fa-file-download"></i> {{ translator.liquidacions }}
                    </button>

                    <!-- <button class="btn btn-primary btn-descargar-csv mr-2" @click="descargaCSVCarrecs('csv')">
                        <i class="far fa-file-download"></i> {{ translator.carrecs }}
                    </button> -->
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                            aria-expanded="false">
                            <i class="far fa-file-download"></i> {{ translator.carrecs }}
                        </button>
                        <div class="dropdown-menu">
                            <button class="dropdown-item" @click="descargaCSVCarrecs('pdf')">PDF</button>
                            <button class="dropdown-item" @click="descargaCSVCarrecs('csv')">CSV</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container-data px-4 py-1">


            <div class="d-flex justify-content-end gap-2 py-3">

                <span>{{ translator.filtrar }}:</span>
                <select v-model="anyos" class="form-control seleccion">
                    <option value="0">{{ translator.data }}</option>
                    <option v-for="( a, i ) in selectAnyos" :key="i">{{ a }}</option>
                </select>
                <select v-model="tipologia" class="form-control seleccion">
                    <option value="">{{ translator.tipologia }}</option>
                    <option v-for="( t, i ) in selectTipologias" :key="i">{{ t }}</option>
                </select>

            </div>

            <table class="listado-facturas">
                <tr class="line-head">
                    <th>{{ translator.liquidacions }}</th>
                    <th>{{ translator.data }}</th>
                    <th class="text-right">{{ translator.import }}</th>
                </tr>
                <tr v-for="(invoice, index) in filteredInvoices" :key="index">
                    <td>
                        <div class="td-flex">
                            <span class="px-2" style="cursor:pointer;" v-if="invoice.pdf != ''"
                                @click.prevent="requestPdf(invoice.pdf)"><i class="fal fa-file-pdf"></i></span>
                            <span style="padding: 0px 12px" v-else>-</span>
                            <span>{{ invoice.text }}</span>
                            <button v-if="invoice.documents == 'true'" class="btn btn-link"
                                @click="pideCargos(invoice)"><i class="fal fa-info-circle"></i></button>
                        </div>

                    </td>
                    <td>{{ invoice.date }}</td>
                    <td class="text-right">{{ invoice.import | ereaseZero }} {{ invoice.currency }}</td>
                </tr>
                <tr>
                    <td></td>
                    <td class="text-right">{{ translator.total }}:</td>
                    <td :class="{ 'total-positive': isTotalPositive, 'total-negative': !isTotalPositive }"
                        class="text-right">{{ sumTotalFiltered | ereaseZero }} {{ currency }}</td>
                    <!-- <td :class="{'total-positive': isTotalPositive, 'total-negative': !isTotalPositive }" class="text-right">{{ totalInvoices }} {{ currency }}</td> -->
                </tr>
            </table>

        </div>


        <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="liq" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="liq">{{ cargo.date }}</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div v-if="Object.keys(cargo).length !== 0" class="d-flex flex-column flex-md-row">
                            <p class="px-2 mr-2" style="cursor:pointer;" v-if="cargo.pdf != ''"
                                @click.prevent="requestPdf(cargo.pdf)"><i class="fal fa-file-pdf"></i></p>
                            <p class="px-2 mr-2" v-else>-</p>
                            <p class="px-2 mr-2" v-if="cargo.date">{{ cargo.date }}</p>
                            <p class="px-2 mr-2" v-if="cargo.text">{{ cargo.text }}</p>
                            <p class="px-2 mr-2" v-if="cargo.import">{{ cargo.import }}{{ cargo.currency }}</p>
                            <p class="px-2 mr-2" v-if="cargo.provider">{{ cargo.provider }}</p>
                            <p class="px-2 mr-2" v-if="cargo.remarks">{{ cargo.remarks }}</p>
                        </div>
                        <div v-else-if="propietariCarrecs.length > 0 && Object.keys(cargo).length === 0">
                            <div class="my-2 fila-cargos" v-for="( c, i ) in propietariCarrecs" :key="i">
                                <div class="d-flex flex-column flex-md-row">
                                    <p class="px-2 mr-2" style="cursor:pointer;" v-if="c.pdf != ''"
                                        @click.prevent="requestPdf(c.pdf)"><i class="fal fa-file-pdf"></i></p>
                                    <p class="px-2 mr-2" v-else>-</p>
                                    <p class="px-2 mr-2" v-if="c.text" style="min-width: 140px;">{{ c.text }}</p>
                                    <p class="px-2 mr-2" v-if="c.import">{{ c.import }}{{ c.currency }}</p>
                                    <p class="px-2 mr-2" v-if="c.provider">{{ c.provider }}</p>
                                    <p class="px-2 mr-2" v-if="c.remarks">{{ c.remarks }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarOwner from "@/components/NavbarOwner.vue";
const $ = require("jquery");

export default {
    name: "OwnerInvoiceSettlement",
    components: {
        NavbarOwner,
    },
    watch: {
        translator() {
            this.$store.dispatch("owner/getInvoiceSettlement", { usr: this.$route.query.id });
        },
        pdf(newQ) {
            this.createPdf(newQ);
        },
        invoiceSettlement() {
            this.invoiceSettlementFilter();
            this.rellenaAnyos();
            this.rellenaTipologias();
        },
        propietariCarrecsDetall(newQ) {
            if (newQ != '') {
                if (newQ.startsWith("77u/")) {
                    this.createCSV(newQ, this.translator.carrecs);
                } else {
                    //console.log(newQ)
                    this.createPdf(newQ);
                }
            }
        },
        propietariCompteDetall(newQ) {
            this.createCSV(newQ, this.translator.liquidacions);
        }
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("owner", ["invoiceSettlement", "pdf", "propietariCarrecs", "propietariCarrecsDetall", "propietariCompteDetall"]),

        filteredInvoices() {
            return this.invoices.filter(invoice => {
                const invoiceYear = invoice.date.split(" ")[0].split("/")[2];
                const matchesYear = this.anyos == 0 || invoiceYear === this.anyos;
                const matchesTipologia = this.tipologia === '' || invoice.tipology == this.tipologia;
                return matchesYear && matchesTipologia;
            });
        },
        sumTotalFiltered() {
            return parseFloat(this.filteredInvoices.reduce((total, invoice) => total + +invoice.import, 0)).toFixed(2);
        }
    },
    data() {
        return {
            isPreLoad: false,
            invoices: [],
            totalInvoices: 0,
            cargo: {},
            isTotalPositive: true,
            currency: "€",
            any: parseInt(this.moment(new Date()).format("yyyy")),
            selectAnyos: [],
            selectTipologias: [],
            anyos: '0',
            tipologia: '',
            //anyActual: parseInt(this.moment(new Date()).format("yyyy"))
        };
    },
    async created() {
        try {
            this.isPreLoad = true;
            this.translator
                ? await this.$store.dispatch("owner/getInvoiceSettlement", { usr: this.$route.query.id })
                : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
        } catch (error) {
            console.error(error);
        } finally {
            this.isPreLoad = false;
        }
    },
    methods: {
        invoiceSettlementFilter() {
            this.invoiceSettlement.forEach(el => {
                el.total ? this.totalInvoices = el.total : this.invoices.push(el);
            });
            this.invoiceSettlement[0].currency != "€" ? this.currency = this.invoiceSettlement[0].currency : null;
            this.totalInvoices < 0 ? this.isTotalPositive = false : this.isTotalPositive = true;

        },
        async requestPdf(idPdf) {
            try {
                if (idPdf != '') {
                    this.isPreLoad = true;
                    await this.$store.dispatch("owner/getPdf", { usr: this.$route.query.id, pdf: idPdf });
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.isPreLoad = false;
            }
        },
        createPdf(pdf) {
            const sampleArr = this.base64ToArrayBuffer(pdf);
            this.openPDF(sampleArr);
        },
        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        },
        openPDF(byte) {
            const blob = new Blob([byte], { type: "application/pdf" });

            const fileUrl = window.URL.createObjectURL(blob);

            function detectMob() {
                const toMatch = [
                    /Android/i,
                    /webOS/i,
                    /iPhone/i,
                    /iPad/i,
                    /iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i
                ];
                return toMatch.some((toMatchItem) => {
                    return navigator.userAgent.match(toMatchItem);
                });
            }

            detectMob()
                ? window.open(fileUrl, "_self")
                : window.open(fileUrl);
        },

        async pideCargos(cargo) {
            console.log(cargo)

            if (cargo.gliquidacio != '' && cargo.liquidacion != '') {
                this.cargo = {};
                await this.$store.dispatch("owner/getPropietariCarrecs", { usr: this.$route.query.id, gliquidacio: cargo.gliquidacio, liquidacio: cargo.liquidacio, id: cargo.id });
                setTimeout(() => {
                    $('#modal').modal('show');
                }, 200)
            } else {
                this.cargo = {};
                this.cargo = cargo
                $('#modal').modal('show');
            }
        },

        async descargaCSVCarrecs(format) {
            await this.$store.dispatch("owner/getPropietariCarrecsDetall", { usr: this.$route.query.id, any: this.anyos, tipologia: this.tipologia, format })
            //await this.$store.dispatch("owner/getPropietariCarrecsDetall", { usr: this.$route.query.id, any: 0, tipologia: '', format })
        },
        async descargaCSVLiquidacions() {
            await this.$store.dispatch("owner/getPropietariCompteDetall", { usr: this.$route.query.id, any: this.anyos })
        },

        createCSV(blobRaw, tipo) {

            let decodedString = atob(blobRaw);
            decodedString = decodedString.substring(3);

            const lines = decodedString.split('\n');
            console.log(lines)
            const uniqueLines = Array.from(new Set(lines));
            const uniqueString = uniqueLines.join('\n');
            let blob = new Blob([uniqueString]);


            const link = document.createElement('a');

            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', `${tipo}${this.tipologia}${this.anyos}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }
        },
        rellenaAnyos() {
            this.selectAnyos = Array.from(new Set(this.invoices.map(obj => {
                const dateParts = obj.date.split(" ")[0].split("/");
                return dateParts[2];
            }))).sort((a, b) => a - b);
        },
        rellenaTipologias() {
            this.selectTipologias = Array.from(new Set(this.invoices.map(obj => obj.tipology))).filter(tipology => tipology !== "").sort();
        }
    },
    filters: {
        ereaseZero(value) {
            if (!value) return "";
            value = String(value.includes(".00")) ? String(value).replace(".00", "") : '';
            return value;
        },
    },
}
</script>

<style lang="scss">
body {
    background-color: #f6f7f9;
}

.line-head {
    height: 50px !important;
    color: var(--colores-gris-medio);
    border-bottom: 1.5px solid #dee2e6;
}

.listado-facturas {
    width: 100%;
}

.container-data {
    background-color: #FFF;
}

.owner .container-data th {
    color: var(--colores-gris-medio) !important;
    font-weight: bold !important;
}

.owner .container-data tr {
    height: 36px;
    border-top: 1px solid #dee2e656;
}

.owner .container-data tr:nth-child(1) {
    border-top: none;
}

.owner .td-flex {
    display: flex;
    align-items: center;
}

.fa-file-pdf {
    color: red;
    font-size: 18px;
}

.total-positive {
    color: green;
    font-weight: bold;
}

.total-negative {
    color: red;
    font-weight: bold;
}

.fila-cargos {
    border-top: 1px solid #dee2e656;
}

.btn-descargar-csv {
    min-width: 150px;
}

.container-data .seleccion {
    max-width: 150px;
    margin-left: 1em;
}
</style>