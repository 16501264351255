<template>
  <div class="pc owner">

    <NavbarOwner :translator="translator" :user="user" class="m-bottom"/>

    <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="container">

      <div class="py-3">
        <div class="row align-items-center">
          <div class="col pr-0">
            <h5 v-cloak><i class="fal fa-calendar"></i> {{ translator.resum }}</h5>
          </div>
        </div>
      </div>

      <!-- Listado propiedades -->
      <section class="section-select-property">
        <select v-model="propertySelected" class="select-property">
          <option disabled value="">{{ translator.propietat }}</option>
          <option v-for="property in ownerProperties" :key="property.property_id" :value="property.property_id">{{ property.property_name }}</option>
        </select>
      </section>
      <!-- ------------------- -->

      <!-- Calendario -->
      <div class="calendar-wrapper">
        <button @click="prevMonth()" id="btnPrev" type="button"><span><i class="fal fa-chevron-left"></i></span></button>
        <button @click="nextMonth()" id="btnNext" type="button"><span><i class="fal fa-chevron-right"></i></span></button>
        <div id="divCal"></div>
      </div>
      <!-- ---------- -->

      <!-- Información día seleccionado -->
      <section id="informeDia">
        <table id="detailsTable"></table>
      </section>
      <!-- ---------- -->
      
    </div>

  </div>
</template>

<script>
const traductor = JSON.parse(sessionStorage.getItem("diccionario"));
var Cal = function(divId) {

  //Store div id
  this.divId = divId;

  // Days of week, starting on Sunday
  const diasMes = JSON.parse(sessionStorage.getItem("diccionario")).weekdayShort;
  let diaMesColocados = [diasMes[1], diasMes[2], diasMes[3], diasMes[4], diasMes[5], diasMes[6], diasMes[0]]
  this.DaysOfWeek = diaMesColocados;


  // Months, stating on January
  this.Months = JSON.parse(sessionStorage.getItem("diccionario")).monthShort;

  // Set the current month, year
  var d = new Date();

  this.currMonth = d.getMonth();
  this.currYear = d.getFullYear();
  this.currDay = d.getDate();
};

// Goes to next month
Cal.prototype.nextMonth = function() {
  if ( this.currMonth == 11 ) {
    this.currMonth = 0;
    this.currYear = this.currYear + 1;
  } else {
    this.currMonth = this.currMonth + 1;
  }
  this.showcurr();
};

// Goes to previous month
Cal.prototype.previousMonth = function() {
  if ( this.currMonth == 0 ) {
    this.currMonth = 11;
    this.currYear = this.currYear - 1;
  } else {
    this.currMonth = this.currMonth - 1;
  }
  this.showcurr();
};

// Show current month
Cal.prototype.showcurr = function() {
  this.showMonth(this.currYear, this.currMonth);
};

// Show month (year, month)
Cal.prototype.showMonth = function (y, m) {

  //var d = new Date() ///<-- no se usa
  // First day of the week in the selected month
  var firstDayOfMonth = new Date(y, m, 1).getDay()
      // Last day of the selected month
      , lastDateOfMonth = new Date(y, m + 1, 0).getDate()
      // Last day of the previous month
      , lastDayOfLastMonth = m == 0 ? new Date(y - 1, 11, 0).getDate() : new Date(y, m, 0).getDate();

  var html = '<table class="calendario-movil">';

  // Write selected month and year
  html += '<thead><tr>';
  html += '<td colspan="7">' + this.Months[m] + ' ' + y + '</td>';
  html += '</tr></thead>';


  // Write the header of the days of the week
  html += '<tr class="days">';
  for (var i = 0; i < this.DaysOfWeek.length; i++) {
      html += '<td>' + this.DaysOfWeek[i] + '</td>';
  }
  html += '</tr>';

  // Write the days
  //var i=1;
  i = 1;
  do {

      var dow = new Date(y, m, i).getDay();
      //console.log(new Date(y, m, i).getDay(), new Date(y, m, i))
      // If Sunday, start new row
        //i == 1 ? console.log('->', dow) : ''
      if (dow == 7) {
          html += '<tr>';
      }
      // If not Sunday but first day of the month
      // it will write the last days from the previous month
      else if (i == 1) {
          html += '<tr>';
          var k = lastDayOfLastMonth - firstDayOfMonth + 2;
          if (firstDayOfMonth == 0) {
            firstDayOfMonth = 7;
            k = lastDayOfLastMonth - firstDayOfMonth + 2;
          }  
          for (var j = 0; j < firstDayOfMonth - 1; j++) {
              html += '<td class="not-current"><span class="num-dia">' + k + '</span></td>';
              k++;
          }
      }

      // Write the current day in the loop
      var chk = new Date();
      var chkY = chk.getFullYear();
      var chkM = chk.getMonth();

      let disponible = '';
      let precioDia = '';

      if (preusMes != '') {
        disponible = preusMes[i-1].availability == "True" ? disponible = 'disponible-si' : disponible = 'disponible-no';
        precioDia = parseFloat(preusMes[i-1].rates[0].price).toFixed(0);
      }
      
      if (chkY == this.currYear && chkM == this.currMonth && i == this.currDay) {
        html += '<td class="today day clickable-day ' + disponible + '" data-day="' + i + '"><span class="num-dia">' + i + '</span><span class="precio">' + precioDia + '</span></td>';
      } else {
        html += '<td class="normal day clickable-day ' + disponible + '" data-day="' + i + '"><span class="num-dia">' + i + '</span><span class="precio">' + precioDia + '</span></td>';
      }



      // If Saturday, closes the row
      if (dow == 0) {
          html += '</tr>';
      }
      // If not Saturday, but last day of the selected month
      // it will write the next few days from the next month
      else if (i == lastDateOfMonth) {
          //var k=1;
          k = 1;
          for (dow; dow <= 6; dow++) {
              html += '<td class="not-current"><span class="num-dia">' + k + '</span></td>';
              k++;
          }
      }

      i++;
  } while (i <= lastDateOfMonth);

  // Closes table
  html += '</table>';

  // Write HTML to the div
  document.getElementById(this.divId).innerHTML = html;
  document.querySelectorAll('.clickable-day').forEach(node=> {
      if (preusMes != '') {
        node.addEventListener('click', ()=> this.selectDay(node));
      }
  });
};

Cal.prototype.selectDay = function(node) {
  let table = '';
  let dayTableShow = node.dataset.day
  let day = node.dataset.day - 1; //-1 para coincidir con el array
  document.querySelector('#informeDia').style.display="block";
  let nightStr = (preusMes[day].minimum_stay == 1)
      ? traductor.nit
      : traductor.nits;

  let restrictionStr = '';
  switch(preusMes[day].restriction) {
      case "A":
          restrictionStr = traductor.arribada
          break;
      case "D":
          restrictionStr = traductor.sortida
          break;
      case "AD":
          restrictionStr = traductor.arribada + ' / ' + traductor.sortida
  }

  let disponible = preusMes[day].availability == "True" ? disponible = 'disponible-si' : disponible = 'disponible-no';

  table = ' <tr class="th-title">';
  table += '  <th scope="row" style="display:flex; align-items:center;">' + '<div class="dispo-color ' + disponible + '"></div> ' + dayTableShow + ' ' + this.Months[this.currMonth] + ' ' + this.currYear +  '</th>';
  table += '  <td class="close-panel">'
  table += '    x'
  table += '  </td>';
  table += '</tr>';

table += ' <tr>';
  table += '  <td scope="row">' + traductor.nitsminim + '</td>';
  table += '  <td class="text-right">'
  table += '    ' + preusMes[day].minimum_stay;
  table += '    ' + nightStr.toLowerCase();
  table += '  </td>';
  table += '</tr>';

  if (preusMes[day].restriction != '') {
    table += '<tr>';
    table += '  <td scope="row">' + traductor.restriccions + '</td>';
    table += '  <td class="text-right">'
    table += '    ' + restrictionStr;
    table += '  </td>';
    table += '</tr>';
  }

  preusMes[day].rates.forEach(rate => {
      table += '<tr>';
      table += '  <td scope="row">' + traductor.tarifa + ' (' + rate.name + ')</td>';
      table += '  <td class="text-right">';
      table += '    ' + Math.ceil(rate.price) + '€';
      table += '  </td>';
      table += '</tr>';
  });

  table += '<tr>';
  table += '  <td scope="row">' + traductor.descompte + '</td>';
  table += '   <td class="text-right">';
  table += '     ' + Math.ceil(preusMes[day].discount.match(/^[\d]+/)[0]) + '%';
  table += '   </th>';
  table += '</tr>';

  table += '<tr>';
  table += '  <td scope="row">' + traductor.descomptelastminute + '</td>';
  table += '   <td class="text-right">';
  table += '     ' + Math.ceil(preusMes[day].discount_lastminute.match(/^[\d]+/)[0]) + '%';
  table += '   </th>';
  table += '</tr>';

  document.querySelector('#detailsTable').innerHTML = table;

  let btnClosePanel = document.querySelector('.close-panel');
  btnClosePanel.addEventListener('click', () => {
    document.querySelector('#informeDia').style.display="none"
  })
};

// On Load of the window
function showCalendar() {
  // Start calendar
  var c = new Cal("divCal");			
  c.showcurr();
}

//==============================//
/////////// Vue //////////////////
//==============================//

let preusMes = ''
var c = new Cal("divCal");

import { mapState } from "vuex";
import NavbarOwner from "@/components/NavbarOwner.vue";
//const $ = require('jquery');

export default {
  name: "OwnerSummary",
  components: {
    NavbarOwner,
  },
  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["ownerProperties", "propietariPreus"]),
  },
  watch: {
    translator() {
      this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id });
    },
    propertySelected() {
      this.loadCalendar();
    },
    propietariPreus() {
      preusMes = this.propietariPreus;
      c.showcurr();
    },
  },
  data() {
    return {
      isPreLoad: false,
      propertySelected: "",
      countMonth: new Date().getMonth() + 1,
      month: '',
      days: ''
    };
  },
  async created() {
    try {
      this.isPreLoad = true;
      this.translator
        ? await this.$store.dispatch("owner/getPropietariPropietats", { usr: this.$route.query.id })
        : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
      preusMes = '';
    } catch (error) {
      console.error(error);
    } finally {
      this.isPreLoad = false;
    }
  },
  mounted() {
    showCalendar();
  },
  methods: {
    loadCalendar() {
      this.$store.dispatch("owner/getPropietariPreus", { usr: this.$route.query.id, id: this.propertySelected, month: this.countMonth, year: new Date().getFullYear() });
    },
    prevMonth() {
      preusMes = '';
      this.countMonth --;
      if (this.propertySelected != '') {
        this.$store.dispatch("owner/getPropietariPreus", { usr: this.$route.query.id, id: this.propertySelected, month: this.countMonth, year: new Date().getFullYear() });   
      }

      c.previousMonth();   
    },
    nextMonth() {
      preusMes = '';
      this.countMonth ++;
      if (this.propertySelected != '') {
        this.$store.dispatch("owner/getPropietariPreus", { usr: this.$route.query.id, id: this.propertySelected, month: this.countMonth, year: new Date().getFullYear() });
      }
      c.nextMonth();
    },
    showDay(day) {
      console.log(day)
    }
   }
}
</script>

<style lang="scss">
body {
  background-color: #f6f7f9;
}
.calendario-movil .days {
  background-color: #ebeff3;
}
.calendario-movil > thead {
  background-color: var(--azul-oscuro);
  color: #FFF;
}

.calendario-movil td.not-current {
  color: #c0c0c0;
  font-size: 14px;
  background-color: #FFF;
}
.calendario-movil td.normal {
  font-size: 14px;
}
.disponible-si {
  background-color: #D1E7DD;
}
.disponible-no {
  background-color: #F8D7DA;
}


//Info día
#informeDia {
  width: 100%;
  margin: auto;
  
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 5px;
  } 
}
#detailsTable {
  width: 100%;
  margin: 20px auto;
  background-color: #fff;

  tbody {
    .th-title {
      border-bottom: 1.8px solid #dee2e6;
    }
    tr {
      td {
        padding: 10px 20px;
        border-top: 1px solid #dee2e656;
      }
      th {
        padding: 10px 20px;
        color: var(--colores-gris-medio);
  
        @media (max-width: 600px) {
          padding: 10px 10px;
        }
  
        .dispo-color {
          width: 20px;
          height: 20px;
          border-radius: 500rem;
          position: relative;
          margin-right: 5px;
        }
      }
      .close-panel {
        cursor: pointer;
        font-weight: 600;
        color: #878787;
        text-align: right;
      }
    } 
  }
}
</style>