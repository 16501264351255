<template>
  <div class="owner">

    <NavbarOwner :translator="translator" :user="user" class="m-bottom"/>

    <div v-if="isPreLoad" style="height: 80vh; display: flex; justify-content: center; align-items: center;">
      <div class="hollow-dots-spinner">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div v-else>

      <div class="container py-3">
        <div class="row align-items-center">

          <div class="col">
            <h5><i class="fal fa-book"></i> {{ translator.resum }}</h5>
          </div>

          <div class="col">
            <h5>
              <a class="p-1" href="#" @click="any += -1">
                <i class="fal fa-angle-left fa-lg"></i>
              </a>
              <span>{{ any }}</span>
              <a v-if="any < anyActual" class="p-1" href="#" @click="any += 1">
                <i class="fal fa-angle-right fa-lg"></i>
              </a>
            </h5>
          </div>

        </div>
      </div>


      <div class="container mt-5" v-if="!isPreLoad && properties.length == 0">
          <h3 class="text-center">{{ translator.noreservesany }}</h3>
      </div>

      <div v-else-if="!isPreLoad && totalProperties != ''" class="container-fluid">

          <!-- Total -->
          <div class="row mb-5">
            <div class="col">
              <h5>{{ translator.total }}</h5>
              <div role="contenido" aria-labelledby="caption" tabindex="0">
                <table class="tabla-contenido">
                  <!-- Titular vertical -->
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ translator.total }}</th>
                      <th>{{ translator.monthNames[0] }}</th>
                      <th>{{ translator.monthNames[1] }}</th>
                      <th>{{ translator.monthNames[2] }}</th>
                      <th>{{ translator.monthNames[3] }}</th>
                      <th>{{ translator.monthNames[4] }}</th>
                      <th>{{ translator.monthNames[5] }}</th>
                      <th>{{ translator.monthNames[6] }}</th>
                      <th>{{ translator.monthNames[7] }}</th>
                      <th>{{ translator.monthNames[8] }}</th>
                      <th>{{ translator.monthNames[9] }}</th>
                      <th>{{ translator.monthNames[10] }}</th>
                      <th>{{ translator.monthNames[11] }}</th>
                    </tr>
                  </thead>
    
                  <tbody>
                    <tr v-if="totalProperties.import_stay.length > 0">
                      <th>{{ translator.estada }}</th>
                      <td v-for="estada in totalProperties.import_stay" :key="estada[0]">{{ estada }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_commissions.length > 0">
                      <th>{{ translator.comissio }}</th>
                      <td v-for="comision in totalProperties.import_commissions" :key="comision[0]">{{ comision }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_extras.length > 0">
                      <th>{{ translator.extres }}</th>
                      <td v-for="extras in totalProperties.import_extras" :key="extras[0]">{{ extras }} {{ currency }}</td>
                    </tr>
                    
                    <tr v-if="totalProperties.import_supplements.length > 0">
                      <th>{{ translator.suplements }}</th>
                      <td v-for="suplemento in totalProperties.import_supplements" :key="suplemento[0]">{{ suplemento }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_fees.length > 0">
                      <th>{{ translator.carrecs }}</th>
                      <td v-for="cargos in totalProperties.import_fees" :key="cargos[0]">{{ cargos }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_cleanings.length > 0">
                      <th>{{ translator.neteja }}</th>
                      <td v-for="limpieza in totalProperties.import_cleanings" :key="limpieza[0]">{{ limpieza }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_taxes.length > 0">
                      <th>{{ translator.tax }}</th>
                      <td v-for="tasa in totalProperties.import_taxes" :key="tasa[0]">{{ tasa }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.import_total.length > 0" style="background-color: #fffbdb">
                      <th>{{ translator.total }}</th>
                      <td v-for="total in totalProperties.import_total" :key="total[0]">{{ total }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.number_reservations.length > 0">
                      <th>{{ translator.reserves }}</th>
                      <td v-for="reservas in totalProperties.number_reservations" :key="reservas[0]">{{ reservas }}</td>
                    </tr>

                    <tr v-if="totalProperties.reservation_nights.length > 0">
                      <th>{{ translator.nits }}/{{ translator.reserva }}</th>
                      <td v-for="nochesReserva in totalProperties.reservation_nights" :key="nochesReserva[0]">{{ nochesReserva }}</td>
                    </tr>

                    <tr v-if="totalProperties.reservation_people.length > 0">
                      <th>{{ translator.persones }}/{{ translator.reserva }}</th>
                      <td v-for="personaReserva in totalProperties.reservation_people" :key="personaReserva[0]">{{ personaReserva }}</td>
                    </tr>

                    <tr v-if="totalProperties.reservation_days.length > 0">
                      <th>{{ translator.anticipacio }}/{{ translator.reserva }}</th>
                      <td v-for="antReserva in totalProperties.reservation_days" :key="antReserva[0]">{{ antReserva }}</td>
                    </tr>

                    <tr v-if="totalProperties.number_days.length > 0">
                      <th>{{ translator.nitsdisponibles }}</th>
                      <td v-for="nochesDispo in totalProperties.number_days" :key="nochesDispo[0]">{{ nochesDispo }}</td>
                    </tr>

                    <tr v-if="totalProperties.number_nights.length > 0">
                      <th>{{ translator.nitsocupades }}</th>
                      <td v-for="nochesOcupadas in totalProperties.number_nights" :key="nochesOcupadas[0]">{{ nochesOcupadas }}</td>
                    </tr>

                    <tr v-if="totalProperties.average_occupation.length > 0">
                      <th>{{ translator.ocupacio }}</th>
                      <td v-for="ocupacion in totalProperties.average_occupation" :key="ocupacion[0]">{{ ocupacion }} %</td>
                    </tr>

                    <tr v-if="totalProperties.average_nights.length > 0">
                      <th>{{ translator.promig }}/{{ translator.nit }}</th>
                      <td v-for="promedioNoche in totalProperties.average_nights" :key="promedioNoche[0]">{{ promedioNoche }} {{ currency }}</td>
                    </tr>

                    <tr v-if="totalProperties.number_people.length > 0">
                      <th>{{ translator.pernoctacions }}</th>
                      <td v-for="pernoctaciones in totalProperties.number_people" :key="pernoctaciones[0]">{{ pernoctaciones }}</td>
                    </tr>

                    <tr v-if="totalProperties.average_people.length > 0">
                      <th>{{ translator.promig }}/{{ translator.persona }}</th>
                      <td v-for="promedioPersona in totalProperties.average_people" :key="promedioPersona[0]">{{ promedioPersona }} {{ currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Propiedades -->
          <div class="row mb-5" v-for="property in properties" :key="property.name">
            <div class="col">
              <h5>{{ property.name }}</h5>
              <div role="contenido" aria-labelledby="caption" tabindex="0">
                <table class="tabla-contenido">
                  <!-- Titular vertical -->
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ translator.total }}</th>
                      <th>{{ translator.monthNames[0] }}</th>
                      <th>{{ translator.monthNames[1] }}</th>
                      <th>{{ translator.monthNames[2] }}</th>
                      <th>{{ translator.monthNames[3] }}</th>
                      <th>{{ translator.monthNames[4] }}</th>
                      <th>{{ translator.monthNames[5] }}</th>
                      <th>{{ translator.monthNames[6] }}</th>
                      <th>{{ translator.monthNames[7] }}</th>
                      <th>{{ translator.monthNames[8] }}</th>
                      <th>{{ translator.monthNames[9] }}</th>
                      <th>{{ translator.monthNames[10] }}</th>
                      <th>{{ translator.monthNames[11] }}</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr v-if="property.import_stay.length > 0">
                      <th>{{ translator.estada }}</th>
                      <td v-for="estada in property.import_stay" :key="estada[0]">{{ estada }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_commissions.length > 0">
                      <th>{{ translator.comissio }}</th>
                      <td v-for="comision in property.import_commissions" :key="comision[0]">{{ comision }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_extras.length > 0">
                      <th>{{ translator.extres }}</th>
                      <td v-for="extras in property.import_extras" :key="extras[0]">{{ extras }} {{property.currency}}</td>
                    </tr>
                    
                    <tr v-if="property.import_supplements.length > 0">
                      <th>{{ translator.suplements }}</th>
                      <td v-for="suplemento in property.import_supplements" :key="suplemento[0]">{{ suplemento }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_fees.length > 0">
                      <th>{{ translator.carrecs }}</th>
                      <td v-for="cargos in property.import_fees" :key="cargos[0]">{{ cargos }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_cleanings.length > 0">
                      <th>{{ translator.neteja }}</th>
                      <td v-for="limpieza in property.import_cleanings" :key="limpieza[0]">{{ limpieza }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_taxes.length > 0">
                      <th>{{ translator.tax }}</th>
                      <td v-for="tasa in property.import_taxes" :key="tasa[0]">{{ tasa }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.import_total.length > 0" style="background-color: #fffbdb">
                      <th>{{ translator.total }}</th>
                      <td v-for="total in property.import_total" :key="total[0]">{{ total }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.number_reservations.length > 0">
                      <th>{{ translator.reserves }}</th>
                      <td v-for="reservas in property.number_reservations" :key="reservas[0]">{{ reservas }}</td>
                    </tr>

                    <tr v-if="property.reservation_nights.length > 0">
                      <th>{{ translator.nits }}/{{ translator.reserva }}</th>
                      <td v-for="nochesReserva in property.reservation_nights" :key="nochesReserva[0]">{{ nochesReserva }}</td>
                    </tr>

                    <tr v-if="property.reservation_people.length > 0">
                      <th>{{ translator.persones }}/{{ translator.reserva }}</th>
                      <td v-for="personaReserva in property.reservation_people" :key="personaReserva[0]">{{ personaReserva }}</td>
                    </tr>

                    <tr v-if="property.reservation_days.length > 0">
                      <th>{{ translator.anticipacio }}/{{ translator.reserva }}</th>
                      <td v-for="antReserva in property.reservation_days" :key="antReserva[0]">{{ antReserva }}</td>
                    </tr>

                    <tr v-if="property.number_days.length > 0">
                      <th>{{ translator.nitsdisponibles }}</th>
                      <td v-for="nochesDispo in property.number_days" :key="nochesDispo[0]">{{ nochesDispo }}</td>
                    </tr>

                    <tr v-if="property.number_nights.length > 0">
                      <th>{{ translator.nitsocupades }}</th>
                      <td v-for="nochesOcupadas in property.number_nights" :key="nochesOcupadas[0]">{{ nochesOcupadas }}</td>
                    </tr>

                    <tr v-if="property.average_occupation.length > 0">
                      <th>{{ translator.ocupacio }}</th>
                      <td v-for="ocupacion in property.average_occupation" :key="ocupacion[0]">{{ ocupacion }} %</td>
                    </tr>

                    <tr v-if="property.average_nights.length > 0">
                      <th>{{ translator.promig }}/{{ translator.nit }}</th>
                      <td v-for="promedioNoche in property.average_nights" :key="promedioNoche[0]">{{ promedioNoche }} {{property.currency}}</td>
                    </tr>

                    <tr v-if="property.number_people.length > 0">
                      <th>{{ translator.pernoctacions }}</th>
                      <td v-for="pernoctaciones in property.number_people" :key="pernoctaciones[0]">{{ pernoctaciones }}</td>
                    </tr>

                    <tr v-if="property.average_people.length > 0">
                      <th>{{ translator.promig }}/{{ translator.persona }}</th>
                      <td v-for="promedioPersona in property.average_people" :key="promedioPersona[0]">{{ promedioPersona }} {{property.currency}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

      </div>

    </div>


  </div>
</template>


<script>

import NavbarOwner from "@/components/NavbarOwner.vue";
import { mapState } from "vuex";

export default {
  name: "OwnerResum",
  components: {
    NavbarOwner,
  },
  data() {
    return {
      isPreLoad: false,
      totalProperties: '',
      properties: [],
      currency: '',
      any: parseInt(this.moment(new Date()).format("yyyy")),
      anyActual: parseInt(this.moment(new Date()).format("yyyy"))
    };
  },

  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("owner", ["propietariResum"]),
  },

  watch: {
    translator() {
      this.getPropietariResum();
    },
    propietariResum() {
        this.isPreLoad = false;
        this.propietariResum.properties[0] ? this.currency = this.propietariResum.properties[0].currency : null;
        
        this.totalProperties = this.propietariResum.total
        this.properties = this.propietariResum.properties;
    },
    any() {
      this.properties = [];
      this.totalProperties = '';
      this.getPropietariResum();
    }
  },

  async created() {
    try {
      this.isPreLoad = true;
      this.translator
        ? this.getPropietariResum()
        : await this.$store.dispatch("utils/getUser", { usr: this.$route.query.id });
    } catch (error) {
        console.error(error);
    }
  },
  methods: {
    async getPropietariResum() {
      this.isPreLoad = true;
      await this.$store.dispatch("owner/getPropietariResum", { usr: this.$route.query.id, any: this.any })
    }
  }


};

</script>

<style>
body {
  background-color: #f6f7f9;
}

.tabla-contenido {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
}

.tabla-contenido td,
.tabla-contenido th {
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 1rem;
}

.tabla-contenido thead th {
    text-align: center;
    font-weight: 600;
    padding: 8px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    background: #dbdbdb;
}

.tabla-contenido td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
}

.tabla-contenido tbody th {
    font-weight: 600;
    text-align: left;
    position: relative;
}

.tabla-contenido thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.tabla-contenido tbody th {
    position: sticky;
    left: 0;
    background: #f6f7f9;
    z-index: 1;
}

/* .tabla-contenido > tbody > tr:nth-child(2n-1) > td {
    background: #f5f5f5;
} */

caption {
    text-align: left;
    padding: 0.25rem;
    position: sticky;
    left: 0;

}

[role="contenido"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: calc(100vh - 80px);
    overflow: auto;
}

@media (max-width: 800px) {
  .tabla-contenido thead th {
    padding: 5px;
  }
  .tabla-contenido {
    font-size: 12px;
  }
  .tabla-contenido tbody th {
    padding: 0.4rem 0.5rem;
  }
}

</style>