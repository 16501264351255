<template>
    <div class="pc owner">

        <div v-if="isPreLoad" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
            <div class="hollow-dots-spinner">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>

        <NavbarOwner :translator="translator" :user="user" class="m-bottom" />

        <div class="container px-4 py-3">

            <h5>{{ translator.benvingut }} {{ user.user_name }}</h5>
        </div>

        <div v-if="is_viewGraphics && !user.owner_block.includes('resum')" class="graficos container">
            <div class="grafico">
                <GraficDonut v-if="propietariGrafics.donut" id="ocupacion"
                    :propietari-grafics="propietariGrafics.donut" />
            </div>
            <div class="grafico" v-if="propietariGrafics.barras.valores != 0">
                <GraficBarres v-if="propietariGrafics.barras" :propietari-grafics="propietariGrafics.barras" />
            </div>
        </div>



        <div class="huesped container mt-3 mb-5" v-if="translator.nits && user.user_name">

            <!-- Llegadas -->
            <router-link v-if="!user.owner_block.includes('arribades')"
                :to="{ name: 'OwnerArrivals', query: { id: this.$route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-sign-in-alt fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.arribades }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisArribades }}
                    </div>
                </div>
            </router-link>

            <!-- Salidas -->
            <router-link v-if="!user.owner_block.includes('sortides')"
                :to="{ name: 'OwnerDepartures', query: { id: this.$route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-sign-out-alt fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.sortides }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisSortides }}
                    </div>
                </div>
            </router-link>

            <!-- Calendario -->
            <router-link v-if="!user.owner_block.includes('calendari')"
                :to="{ name: 'OwnerCalendar', query: { id: $route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-calendar-star fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.calendari }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisCalendari }}
                    </div>
                </div>
            </router-link>

            <!-- Liquidaciones -->
            <router-link v-if="!user.owner_block.includes('liquidacions')"
                :to="{ name: 'OwnerInvoiceSettlement', query: { id: $route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-wallet fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.liquidacions }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisLiquidacions }}
                    </div>
                </div>
            </router-link>

            <!-- Facturas -->
            <router-link v-if="!user.owner_block.includes('factures')"
                :to="{ name: 'OwnerInvoices', query: { id: $route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-file-archive fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.factures }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisFactures }}
                    </div>
                </div>
            </router-link>

            <!-- Resumen resum-->
            <router-link v-if="!user.owner_block.includes('resum')"
                :to="{ name: 'OwnerResum', query: { id: $route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-hands fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.resum }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        {{ translator.propietarisResum }}
                    </div>
                </div>
            </router-link>

            <!-- Reservas pasadas-->
            <router-link v-if="!user.owner_block.includes('reserves')"
                :to="{ name: 'OwnerReservations', query: { id: $route.query.id } }"
                class="contenedor-reserva shadow-sm d-flex align-items-center">
                <div class="icono">
                    <i class="fal fa-book-alt fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.reserves }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        <!-- {{ translator.propietarisReserves }} -->
                    </div>
                </div>
            </router-link>

            <!-- Contrato -->
            <div v-if="propietariContracte" class="contenedor-reserva shadow-sm d-flex align-items-center"
                @click="openPDF(propietariContracte)">
                <div class="icono">
                    <i class="fal fa-file-download fa-2x"></i>
                </div>
                <div class="info pl-3">
                    <div class="periodo-estancia" v-cloak>
                        {{ translator.contracte }}
                    </div>
                    <div class="nombre-alojamiento" v-cloak>
                        <!-- {{ translator.propietarisResum }} -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import NavbarOwner from '@/components/NavbarOwner.vue';
import { mapState } from "vuex";

import GraficDonut from '@/components/GraficDonut.vue';
import GraficBarres from '@/components/GraficBarres.vue';



export default {
    name: 'Owner',
    components: {
        NavbarOwner,
        GraficDonut,
        GraficBarres
    },
    computed: {
        ...mapState("utils", ["user", "translator"]),
        ...mapState("owner", ["propietariGrafics", "propietariContracte"]),
    },
    watch: {
        async translator() {
            this.isPreLoad = false;
            this.$store.dispatch("owner/getPropietariContracte", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            this.$store.dispatch("owner/getPropietariGrafics", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        },
        propietariGrafics() {
            this.is_viewGraphics = true
        }
    },
    data() {
        return {
            isPreLoad: false,
            is_viewGraphics: false
        };
    },
    async created() {
        try {
            this.isPreLoad = true;
            if (this.translator) {
                await this.$store.dispatch("owner/getPropietariContracte", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                await this.$store.dispatch("owner/getPropietariGrafics", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } else {
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            }
            this.isPreLoad = false;

        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        },
        openPDF(byte) {
            const pdf = this.base64ToArrayBuffer(byte)
            const blob = new Blob([pdf], { type: "application/pdf" });
            const fileUrl = window.URL.createObjectURL(blob);

            function detectMob() {
                const toMatch = [
                    /Android/i,
                    /webOS/i,
                    /iPhone/i,
                    /iPad/i,
                    /iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i
                ];
                return toMatch.some((toMatchItem) => {
                    return navigator.userAgent.match(toMatchItem);
                });
            }

            detectMob()
                ? window.open(fileUrl, "_self")
                : window.open(fileUrl);
        },
    }
}
</script>

<style scoped>
.graficos {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.grafico {
    width: 100%;
}

@media (min-width: 800px) {
    .graficos {
        flex-direction: row;
        justify-content: center;
    }

    .grafico {
        width: 50%;
    }
}
</style>
